<template>
 
  <section class="cart-page">
   
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="spinnerPackage" v-show="spinnerPackage"></div>
         
          <h1 class="text-center fw-bold">Détail : {{details[0].package.libelle}}</h1>
          <table class="table cart-table">
            <thead>
              <tr>
                <th class="preview pad">Libelle</th>
                <th class="product pad">Catégorie</th>
                <th class="price pad">Coût (Fcfa)</th>
                <th class="quantity pad">Prix Unitaire (Fcfa)</th>
                <th class="total pad">Coût de vente (Fcfa)</th>
                <th class="total pad line">Marge de gain par produit (Fcfa)</th>
                <th class="total pad line">Nombre de produits</th>
               
               
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in details" :key="index">
                <td class="product first_letter">
                  {{ item.package.libelle }}
                </td>
                <td class="product">
                  {{ item.package.type.libelle }}
                </td>
                <td class="product">
                  {{
                    moneyFormat.format(item.package.cout_acquisition * nombreOfProductGolbal)
                  }}
                 
                </td>
                <td class="product">
                  <div class="">{{ moneyFormat.format(item.package.cout_acquisition) }}</div>
                </td>
                <td class="product">{{ moneyFormat.format(item.package.cout_vente) }} </td>
                <td class="product">{{moneyFormat.format(item.package.cout_vente-item.package.cout_acquisition) }}</td>
                <td class="product">{{ nombreOfProductGolbal }}</td>
                
               

              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row bottom-box">
        <div>
          <h2 style="text-align: center">Rapport de vente</h2>
          <table class="tble">
            <tr class="border">
              <th class="boder">Nombre de produits vendus</th>
              <th class="border">Coût (FCFA)</th>
             
              <th class="border">Date d'enregistrement</th>
            </tr>
            <tr v-for="(item, index) in rapports" :key="index">
              <td class="border tble1">{{ item.produits_vendus }}</td>
              <td class="border">{{ moneyFormat.format(item.cout) }}</td> 

              <td class="border">{{ new Date(item.created_at).toLocaleDateString('fr') }}</td>
            </tr>
             <tr>
              <td class="border tble1">Total : {{totalPackageVendu}}</td>
             </tr>
            
          </table>
        </div>
      </div>
      <div class="cart-total-box row">
        <div class="col-lg-5 col-sm-6 col-xs-12 pull-right"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
moment.locale("fr");
export default {
  name: "details",
  components: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      details: null,
      rapports: null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      nombreOfProductGolbal:"",
      totalPackageVendu:"",
      spinnerPackage:false,
    };
  },

  methods: {
     getSomme(tableau){
      let somme = 0;
     tableau.forEach(item=>{
            somme+=item.produits_vendus
      })
      return somme
     },
    detailSouscription() {
      this.spinnerPackage = true
      axios.get("api/achats/" + this.$route.params.id)
        .then((res) => {
          //console.log("Res",res.data)
         
          //console.log("DET", res);
          this.details = res.data.data;

          this.rapports = this.details[0].rapport;
          this.totalPackageVendu = this.getSomme(this.rapports)
         
          this.nombreOfProductGolbal = this.details[0].nb_pieces
          this.spinnerPackage = false
          
          //console.log("RAPPORT", this.rapports);
          //console.log("this.nombreOfProductGolbal", this.nombreOfProductGolbal);
        })
        .catch(err=>{
          //console.log(err)
          this.spinnerPackage = false
        })

            },
  },
  created() {
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = JSON.parse(localStorage.getItem('user')).data
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    //console.log("USER", this.user.id);
    this.detailSouscription();
    
  },
};
</script>

<style scoped>


h1{
  padding:1em 0;
}
.spinnerPackage {
  border: 5px solid #ccc; /* Taille et couleur des bordures */
  border-top: 5px solid #ff6a00; /* Couleur de la bordure supérieure */
  border-radius: 50%; /* Arrondir les coins pour créer un cercle */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Animation de rotation */
  position:absolute;
  margin-left:50%;
  transform:translateX(-50%);
  margin-top: 10%;

}
.cart-page{
  padding:0;
}
td,th{
  font-weight:bold;
  text-align: center;
}
th,.pad{
  background: gray !important;
  color:white;
}
.badge{
  background-color:rgb(222, 175, 34) !important;
}
.fw-bold{
font-weight:bold;
}
.border {
  border-width: 1px;
  border-style: solid;
  padding: 2%;
}
.tble {
  border-collapse: collapse;
  width: 80%;
  margin: 0 auto;
}



.pad {
  padding: 0 !important;
}
.line {
  line-height: 30px !important;
}
</style>
