<template>
  <div class="container bg-light align-items-center position-relative">
    <!-- <section class="hide_solde" v-show="verifMotPassePourConfirmation">
     <div class="somme">
      <p class="text-dark text-center">Votre mot de passe est <br/> réquis pour voir votre compte</p>
      <input type="password" v-model="passwordForSeeSolde" class="form-control" placeholder="xxxxxxx">
      <button class="btn-lg bg-dark position-relative" 
      :disabled="passwordForSeeSolde ? false:true"
       @click="deverouillerCompte" v-show="!spinner"> 
          Entrée</button>
          <span class="spinner" v-show="spinner"></span>
      
     </div>

    </section> -->

    <div class="card box1">
      <div class="fw-bolder text-center carte-solde">
        <h1>
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"
            >
              <path d="M16 12h2v4h-2z"></path>
              <path
                d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM5 5h13v2H5a1.001 1.001 0 0 1 0-2zm15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15v10z"
              ></path></svg
          ></span>
          Solde
          <svg
            v-show="showSolde"
            @click="showSolde = !showSolde"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-eye-slash"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
            />
            <path
              d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
            />
            <path
              d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
            />
          </svg>

          <svg
            v-show="!showSolde"
            @click="showSolde = !showSolde"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-eye"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
            />
            <path
              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
            />
          </svg>
        </h1>
        <h1 class="solde">
          <span v-show="showSolde">{{ moneyFormat.format(userSolde) }}</span>
          <span v-show="!showSolde">*****</span>
          <b class="devise">fcfa</b>
        </h1>
      </div>
    </div>
    <div class="card box2 ">
      <div class="text-center">
        <h3 class="fw-bold Rechargement m-0">Rechargement ou retrait</h3>
      </div>
      <ul class="nav nav-tabs mb-3 px-md-4 px-2"></ul>
      <div class="type_operation">
        <label> Type d'opération </label>
        <select class="form-control" v-model="transaction">
          <option value="" disabled>Sélectionne le type de l'opération</option>
          <option value="depot">Dépôt</option>
          <option value="retrait">Retrait</option>
        </select>
      </div>
      <form>
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-column px-md-5 px-4 mb-4 montant-preuve">
              <div v-if="transaction == 'retrait' || transaction == 'depot'">
                <label>Montant</label>
                <div class="inputWithIcon">
                  <input
                    class="form-control input-text"
                    type="number"
                    placeholder="Entrez le montant svp"
                    v-model="amount"
                  />
                  <span class="">
                    <img
                      src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-logok-15.png"
                      alt="logo"
                    />
                  </span>
                </div>
              </div>

              <div
                class="type_operation"
                v-if="
                  (transaction == 'retrait' && 500000 >= amount && amount) ||
                    transaction == 'depot'
                "
              >
                <label> Moyen de paiement </label>

                <multiselect
                  v-model="moyen_payment"
                  placeholder="Sélectionne le moyen de paiement"
                  label="libelle"
                  track-by="libelle"
                  :options="listMoyenPayment"
                  :option-height="205"
                  :show-labels="false"
                  @input="verifieMontant"
                >
                  <template slot="option" slot-scope="props">
                    <img
                      class="option__image"
                      :src="props.option.img"
                      :alt="props.option.img"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.libelle
                      }}</span>
                    </div>
                  </template>
                </multiselect>
                <!-- <select
                  class="form-control"
                  v-model="moyen_payment"
               
                >
                  <option value="" disabled
                    >Sélectionne le moyen de paiement</option
                  >
                  <option>
                    <img src="../assets/moov.png" class="w-25">
                    libre
                  </option>
                  <option
                    :value="item.id"
                    :id="item.max"
                    v-for="(item, index) in listMoyenPayment"
                    :key="index"
                    >{{ item.libelle }}
                    </option
                  >
                </select> -->
              </div>
              <div v-if="transaction == 'retrait' && amount">
                <section v-if="500000 >= amount">
                  <label>Numéro de télephone</label>
                  <div class="inputWithIcon">
                    <input
                      class="form-control input-text"
                      type="text"
                      placeholder="Téléphone"
                      v-model="number_phone"
                      pattern="^[0-9]{10}$"
                    />
                  </div>
                </section>

                <section v-if="amount > 500000">
                  <label>Numéro de carte bancaire</label>
                  <div class="inputWithIcon">
                    <input
                      class="form-control input-text"
                      type="text"
                      placeholder="Numéro bancaire"
                      v-model="number_bancaire"
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="transaction == 'depot'">
            <div class="d-flex flex-column px-md-5 px-4 mb-4 montant-preuve">
              <label>Preuve</label>
              <div class="inputWithIcon">
                <input
                  class="text-uppercase"
                  type="file"
                  placeholder="Entrez votre nom svp"
                  ref="photos"
                />
                <!-- <span class="far fa-user"></span> -->
              </div>
            </div>
          </div>

          <div v-if="transaction && verifMotPassePourConfirmation === false">
            <label class="text-dark"
              >Votre mot de passe est requis pour effectuer une
              transaction</label
            >
            <input
              type="password"
              v-model="passwordForSeeSolde"
              class="form-control"
              placeholder="xxxxxxx"
            />
            <button
              class="btn-lg bg-dark bouton_passowrd"
              :disabled="passwordForSeeSolde ? false : true"
              @click.prevent="deverouillerCompte"
              v-if="spinnerVerif === false"
            >
              Entrer
            </button>
            <span class="spinner" v-else></span>
          </div>

          <div
            class="col-12 px-md-5 px-4 mt-3"
            v-if="
              (transaction == 'retrait' && montant_max >= amount) ||
                transaction == 'depot' ||
                (transaction == 'retrait' && number_bancaire)
            "
          >
            <Preloader v-if="load" color="red" scale="0.6" />
            <button
              v-if="verifMotPassePourConfirmation === true"
              type="submit"
              :disabled="amount ? false : true"
              @click.prevent="reloading"
              class="btn btn-primary w-100 text-uppercase bout update"
            >
              Valider
            </button>
          </div>
          <p
            v-if="
              amount > montant_max &&
                moyen_payment &&
                500000 >= amount &&
                transaction == 'retrait'
            "
            class="text-danger fw-bold"
          >
            Le montant demandé ne peut dépasser le plafond autorisé pour ce mode
            de paiement : ({{ moneyFormat.format(montant_max) }} Fcfa)
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Preloader from "@/components/preloader.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "operation",

  components: {
    Preloader,
    Multiselect,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      amount: null,
      spinner: false,
      nom: "",
      account: null,
      type: null,
      transaction: "",
      load: false,
      showSolde: false,
      moyen_payment: null,
      number_phone: "",
      number_bancaire: "",
      listMoyenPayment: [],
      montant_max: "",
      userSolde: "",
      passwordForSeeSolde: null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      verifMotPassePourConfirmation: false,
      spinnerVerif: false,
    };
  },

  methods: {
    deverouillerCompte() {
      this.spinnerVerif = true;
      axios
        .post("api/auth/login", {
          email: this.user.email,
          password: this.passwordForSeeSolde,
        })
        .then((response) => {
          if (response.data.status === "true") {
            this.verifMotPassePourConfirmation = true;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Mot de passe incorrect.",
            showConfirmButton: true,
          });
          this.verifMotPassePourConfirmation = false;
          this.spinnerVerif = false;
        });
    },
    verifieMontant() {
      this.listMoyenPayment.forEach((item) => {
        if (item.id == this.moyen_payment.id) {
          this.montant_max = item.max;
        }
      });
      //console.log("this.montant_max", this.montant_max);
    },
    getListMoyenPayment() {
      const LISTlOGO = [
        "assets/moov.png",
        "assets/mtn.png",
        "assets/orange.jpg",
        "assets/wave.png",
        "assets/virrement-bancaire.jpg",
      ];
      //console.log('LISTlOGO',LISTlOGO[0])
      axios
        .get("api/moyens")
        .then((res) => {
          //console.log("this.listMoyenPayment", res);
          res.data.data.forEach((item) => {
            //console.log(item)
            if (item.libelle == "ORANGE") {
              item.img = location.origin + "/" + LISTlOGO[2];
            } else if (item.libelle == "WAVE") {
              item.img = location.origin + "/" + LISTlOGO[3];
            } else if (item.libelle == "MOOV") {
              item.img = location.origin + "/" + LISTlOGO[0];
            } else if (item.libelle == "MTN") {
              item.img = location.origin + "/" + LISTlOGO[1];
            } else {
              item.img = location.origin + "/" + LISTlOGO[4];
            }
          });
          this.listMoyenPayment = res.data.data;
          //console.log("this.listMoyenPayment",this.listMoyenPayment)
        })
        .catch((error) => {
          console.log("COMPTE", error);
        });
    },
    reloading() {
      let file = "";
      //console.log(this.user.id);
      //console.log(this.transaction);
      //console.log(this.amount);
      this.load = true;
      let operation = new FormData();
      //console.log(this.$refs.photos);
      // const phoneRegex = /^[0-9]{10}$/;
      if (this.$refs.photos) {
        file = this.$refs.photos.files[0];
        operation.append("proof", file);
      }
      let numero = this.number_phone ? this.number_phone : this.number_bancaire;
      operation.append("user_id", this.user.id);
      operation.append("type", this.transaction);
      operation.append("amount", this.amount);
      operation.append("moyen_id", this.moyen_payment.id);
      operation.append("numero", numero);
      //console.log("operation", operation);
      if (
        (this.transaction == "depot" && this.amount > 0 && file) ||
        (this.transaction == "retrait" &&
          this.amount > 0 &&
          this.number_phone &&
          this.moyen_payment) ||
        this.number_bancaire
      ) {
        axios
          .post("api/operations", operation)
          .then((res) => {
            //console.log("COMPTE", res);
            this.account = res.data;
            this.load = false;
            if (res.data.status) {
              Swal.fire("Succes", "Opération effectuée", "success");
              this.amount = "";
              this.transaction = "";
              this.number_bancaire = "";
              this.number_phone = "";
            } else {
              Swal.fire("error", res.data.message, "error");
              this.load = false;
            }
          })
          .catch((error) => {
            this.load = false;
            //console.log("COMPTE", error);
          });
      } else {
        Swal.fire("info", "Remplissez tous les champs", "info");
        this.load = false;
      }
    },
  },

  mounted() {
    this.getListMoyenPayment();
    this.infoUserWithoutData = JSON.parse(localStorage.getItem("user"));
    this.infoUserWithData = JSON.parse(localStorage.getItem("user")).data;
    this.user = this.infoUserWithData
      ? this.infoUserWithData
      : this.infoUserWithoutData;
    this.userSolde = this.infoUserWithData
      ? this.infoUserWithData.solde
      : this.infoUserWithoutData.solde;

    this.number_phone = this.user.phone;
    this.number_bancaire = this.user.bank_card;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
.bouton_passowrd {
  margin: 0.5em 0;
  padding: 0.2em;
  cursor: pointer;
  color: white;
  background: black;
}
.text-dark {
  color: black !important;
}
select {
  height: 50px;
}
.spinner {
  border: 5px solid #ccc;
  border-top: 5px solid #ff6a00;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  animation: spin 1s linear infinite;
  position: absolute;
  margin: 10px 0 0 0;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}
.hide_solde {
  position: absolute;
  top: 309px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  padding: 5em;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
}
.hide_solde div {
  width: 400px;
  padding: 5em 0;
  color: white;
  text-align: left;
  margin-left: 50%;
  transform: translatex(-50%);
}
.hide_solde div button {
  width: auto !important;
  margin: 1em 0;
  height: auto !important;
  background: rgb(0, 0, 0);
  padding: 0.5em 1.5em;
}
.hide_solde input {
  width: 80%;
  height: 50px;
  padding: 0 1em;
  margin-left: 50%;
  transform: translateX(-50%);
}
.bi-eye-slash,
.bi-eye {
  cursor: pointer;
}
.color-send {
  background-color: rgb(75, 171, 75);
}

.container {
  margin: 20px auto;
  height: 90vh;
  padding: 5em 3em;
  display: flex;
}
.montant-preuve,
label {
  margin: 0.5em 0;
}
.card.box1 {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-color: rgb(218, 165, 32);
  color: rgb(253, 253, 253);
  font-weight: bold;
  margin-right: 30px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.459);
  transform: rotate(4deg);
}

.card.box2 {
  width: 100%;
  height: 250px;
  /* background-color: #ffffff; */
  border-radius: 0;
}

.text {
  font-size: 13px;
}

.box2 .btn.btn-primary.bar {
  width: 20px;
  /* background-color: transparent; */
  border: none;
  color: #3ecc6d;
}
@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    padding: 2em 10em;
  }
  .card.box1 {
    margin-right: 0;
    margin-bottom: 5em;
  }
  .form-control {
    width: 100%;
  }
  input {
    width: 100%;
  }
}

.box2 .btn.btn-primary.bar:hover {
  color: #baf0c3;
}

.box1 .btn.btn-primary {
  background-color: #57c97d;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.box1 .btn.btn-primary:hover {
  color: #57c97d;
}
.color_green {
  background-color: rgb(67, 155, 67);
}

.btn.btn-success {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.nav.nav-tabs {
  border: none;
  border-bottom: 2px solid #ddd;
}

.nav.nav-tabs .nav-item .nav-link {
  border: none;
  color: black;
  border-bottom: 2px solid transparent;
  font-size: 14px;
}

.nav.nav-tabs .nav-item .nav-link:hover {
  border-bottom: 2px solid #3ecc6d;
  color: #05cf48;
}

.nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-bottom: 2px solid #3ecc6d;
}

.form-control {
  border: 1px solid black;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  border-radius: 0;
}

.inputWithIcon {
  position: relative;
}

img {
  width: 41px;
  height: 41px;
  object-fit: cover;
}

.inputWithIcon span {
  position: absolute;
  right: 0px;
  bottom: 9px;
  color: #57c97d;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
}

.form-control:focus {
  border-bottom: 1px solid #ddd;
}

.btn-outline-primary {
  color: black;
  background-color: #ddd;
  border: 1px solid #ddd;
}

.btn-outline-primary:hover {
  background-color: #05cf48;
  border: 1px solid #ddd;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #baf0c3;
  background-color: #3ecc6d;
  box-shadow: none;
  border: 1px solid #ddd;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-radius: 50px;
  margin-right: 20px;
}

form {
  font-size: 14px;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #0d6efd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.somme {
  padding: 0 2em;
}

form .btn.btn-primary {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin-top: 20px;
}

/*form .btn.btn-primary:hover {
  background-color: #05cf48;
}*/

@media (max-width: 750px) {
  .container {
    padding: 10px;
    width: 100%;
  }

  .text-green {
    font-size: 14px;
  }

  .card.box1,
  .card.box2 {
    width: 100%;
  }

  .nav.nav-tabs .nav-item .nav-link {
    font-size: 12px;
  }

  .choix {
    display: flex !important;
  }
}

.solde {
  font-size: 4em !important;
  margin-top: 10px !important;
  margin-left: 0.5em;
}

.bout {
  background-color: rgb(55, 244, 67);
  color: #000;
  border-radius: 30px;
}

.update {
  position: relative;
  z-index: 5;
  border-radius: 0;
}

.update:hover.update::before {
  opacity: 1;
}

.update::before {
  transition: all 0.5s ease-in-out;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.input-text {
  width: 100%;
  height: 59px;
  padding: 0 1em;
  border: 1px solid rgb(112, 112, 112);
}
.carte-solde {
  padding: 2.5em 0 0 0;
}
.devise {
  font-size: 0.5em;
}
.Rechargement {
  padding: 0.2em;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.332);
}
</style>
