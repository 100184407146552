<template>
	<div>
		<header>
			<div class="container-fluid top_header">
				<div class="container cadre_bienvenue">
					
					<div class="float_right">
						<ul>
							<li><a href="#"><i class="fa fa-facebook text-light"></i></a></li>
							<li><a href="#"><i class="fa fa-twitter text-light"></i></a></li>
							<li><a href="#"><i class="fa fa-google-plus text-light"></i></a></li>
							<li><a href="#"><i class="fa fa-linkedin text-light"></i></a></li>
						</ul>
					</div>
					<p class="text-light"><span class=" icon icon-Pointer mx-2"></span>RIVIERA GOLF</p>
					<p class="text-light"><span class="separator icon icon-Phone2 mx-2"></span>	0574146565</p>
				</div>
			</div>
			<div class="bottom_header top-bar-gradient text-center">
				<div class="container clear_fix">
					<div class="float_left logo">
						<a href="#">
							<img src="/images/img18.jpg" alt="" width="40%">
						</a>
					</div>
					<!-- <div class="float_right address">
						<div class="top-info">
							
							<div class="content-box">
								<h4><span class=" icon icon-Pointer mx-2"></span>RIVIERA GOLF</h4>
							</div>
						</div>
						<div class="top-info">
							<div class="icon-box">
														
							</div>
							<div class="content-box">
								<h4><span class="separator icon icon-Phone2 mx-2"></span>	0777589211</h4>
							</div>
						</div>
						
					</div> -->
				</div>
			</div>
		</header>
		<section class="mainmenu-area stricky">
			<div class="container bar">
				<nav class="clearfix onglet">
					<div class="navbar-header clearfix">
						<button type="button" class="navbar-toggle collapsed navigations">
							<span class="sr-only">Toggle navigation</span>
							<span class="fa fa-th fa-2x"></span>
						</button>
					</div>
					<div class="nav_main_list custom-scroll-bar pull-left" id="bs-example-navbar-collapse-1">
						<ul class="nav nav-btn navbar-nav" id="hover_slip">
							<li class="accueil" @click.prevent="closeMenu" >
						
								<router-link to="/" class="nav-btn">Accueil</router-link>
							      <div class="onboarding-accueil" v-if="sliderNext == 0 && localStorageCount == 1">
									<p>Ceci est la page d'accueil</p>
									<div class="conteneur-btn">
										<button class="btn bg-danger" @click.prevent="closeTuto">Fermer le tutoriel</button>
										<button class="btn bg-warning " @click.prevent="next">Suivant</button>
									</div>
								  </div>
							</li>
							<li class="arrow_down packages" @click.prevent="closeMenu">
								 <router-link to="/packages-index" class="nav-btn">Packages</router-link>    
								 <div class="onboarding-packages" v-if="sliderNext == 1 && localStorageCount == 1">
									<p>L'onglet packages , vous permet de voir les différents packages <br> et de faire une souscription.</p>
									<div class="conteneur-btn">
										<button class="btn bg-danger " @click.prevent="previous">Précédent</button>
										<button class="btn bg-warning " @click.prevent="next">Suivant</button>
									</div>
								  </div>
							</li>
							<li class="arrow_down about_us" @click.prevent="closeMenu">
								
								<router-link to="/about" class="nav-btn">A Propos de nous</router-link> 
								<div class="onboarding-about_us" v-if="sliderNext == 2 && localStorageCount == 1">
									<p>Vous pouvez savoir tous sur INVEST dans cet onglet.</p>
									<div class="conteneur-btn">
										<button class="btn bg-danger " @click.prevent="previous">Précédent</button>
										<button class="btn bg-warning " @click.prevent="next">Suivant</button>
									</div>
								  </div>

							</li>
							<li class="pr-3 contact" @click.prevent="closeMenu">
								<router-link to="/contact" class="nav-btn">contact</router-link>
								<div class="onboarding-contact" v-if="sliderNext == 3 && localStorageCount == 1">
									<p>Vous pouvez avoir tous nos contacts dans cet onglet,<br>
									téléphone,boite mail,etc....</p>
								<div class="conteneur-btn">
										<button class="btn bg-danger " @click.prevent="previous">Précédent</button>
										<button class="btn bg-warning " @click.prevent="next">Suivant</button>
									</div>
								  </div>
							</li>
							
							<li v-if="user == null" class="login"  @click.prevent="closeMenu" >
								<router-link to="/auth" class="nav-btn">Se connecter</router-link>
								<div class="onboarding-login" v-if="sliderNext == 4 && localStorageCount == 1">
									<p>Ici,c'est l onglet pour se connecter et voir : <br>
									-Son profil , solde <br>
									-ses investissements <br>
									-Faire des opérations et voir ses opérations.</p>
								<div class="conteneur-btn">
										<button class="btn  bg-warning " @click.prevent="previous">Précédent</button>
										<button class="btn bg-danger" @click.prevent="closeTuto">Fermer le tutoriel</button>
									</div>
								  </div>
							</li>
							
							<li v-if="user != null" class="pull-right" @click.prevent="mobileMenu = !mobileMenu">
								<a class="nav-btn"> 
									<i class="fa fa-user" >

								</i> {{user.prenoms}} {{user.nom}}
							</a>
								<div class="sub-menu">
									<ul>
										<li ><router-link to="/profil/" >Profil</router-link></li>
										<li><router-link to="/operations">Mes opérations</router-link></li>
										<li><router-link to="/souscription">Mes investissements</router-link></li>
										<li><router-link to="/operation">Mon compte</router-link></li>
										
										<li><a href="javascript:void(0)" @click="deconnexion">Déconnexion</a></li>
									</ul>
							</div>
							<div class="sub-menu-mobile" v-if="mobileMenu">
								<ul class="list-menu">
									<li><router-link to="/profil/">Profil</router-link></li>
									<li><router-link to="/operations">Mes opérations</router-link></li>
									<li><router-link to="/souscription">Mes investissements</router-link></li>
									<li><router-link to="/operation">Mon compte</router-link></li>
									
									<li><a href="javascript:void(0)" @click="deconnexion">Deconnexion</a></li>
								</ul>
						</div>
							
							</li>
							
						</ul>
						
						
					</div>
					
				</nav>
			</div>
		</section>
	</div>

</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name:'header',
	components:{
	},

	data(){
       return{
          user:JSON.parse(localStorage.getItem('user')),
		  sliderNext:0,
		  count:0,
		  mobileMenu:false,
		  localStorageCount:localStorage.getItem('count')
	   }
	},
	
	
    methods: {
		closeMenu(){
			// console.log(window.innerWidth)
			if(  1166 >= window.innerWidth
){

	let idMenu = document.getElementById("bs-example-navbar-collapse-1")
		 idMenu.classList.add("mCS_no_scrollbar")
		 idMenu.style.display="none" 
		//  console.log("idMenu",idMenu)
} else{
	return;
}
		},
		launchTutoOne(){
         while(this.count < 1){
			
			this.count++
			localStorage.setItem("count",this.count)
		 }
		},
		closeTuto(){
          this.sliderNext = 5
		  localStorage.removeItem("count")
		},
		previous(){
			this.sliderNext--
		},
		next(){
     this.sliderNext++
	 //console.log(this.sliderNext)
		},
		deconnexion(){
			let app =this
            axios.get('api/auth/logout',
			{ headers:{"Authorization" : 'Bearer ' + localStorage.getItem('token')}
			}).then( function(reponse){
				//console.log(reponse.data.status);
				app.$router.push("/")  
				window.location.href='/'
				app.user=null;
			})
			.catch( function(error){
					//console.log(error)
			})  
			
			localStorage.removeItem('token')
					localStorage.removeItem('user')
					Swal.fire({
						position: 'center',
						icon:'success',
						title: 'Vous êtes deconnecté',
						showConfirmButton: false,
						timer: 1500,
					})  
    	},
	},
	mounted() {
		this.launchTutoOne()
		//console.log('this.sliderNext',this.sliderNext)
		this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData =  this.user ? JSON.parse(localStorage.getItem('user')).data : null
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
		
		
	},

	
}
</script>

<style scoped>



.sub-menu-mobile{
	display: none;
	
}

@media screen and (max-width:1167px) {
	.sub-menu-mobile{
		display: block; 
		
	}
	.list-menu{
		position:static;
		
		
		background: white;
		border-radius: 10px;
	}
	.list-menu li a{
		color:black !important;
	}
}

.bg-warning { 
	background: rgb(244, 179, 0) !important;
}
.bg-danger{
	background: crimson !important;
}
.conteneur-btn{
	text-align:center;
}
.conteneur-btn button{
	margin:0 .5em;
	font-weight: bold;
	color:white !important;
}
.navbar-nav .accueil,.navbar-nav .packages,.navbar-nav .about_us,.navbar-nav .contact,.navbar-nav .login{
	position:relative;
}
.onboarding-accueil,.onboarding-packages,.onboarding-about_us,.onboarding-contact,.onboarding-login{
	width:350px;
	height:auto;
	padding:.9em .9em 1.5em .9em;
	right:0;
	text-align:justify;
	background:white;
	position:absolute;
	z-index: 9999;
	border-radius:8px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.363);
}
.address{
	padding:2em 0 0 0;
}
.cadre_bienvenue{
	display:flex;
	justify-content: space-between;
	align-items: center;
}
@media screen and (max-width:1168px) {
	.cadre_bienvenue{
		flex-direction: column;
		text-align: center;
	}
	.onboarding-accueil,.onboarding-packages,.onboarding-about_us,.onboarding-contact,.onboarding-login{
		display:none
 }
}
header .bottom_header{ 
	background-image:url('../slides/img5.jpg'),linear-gradient(rgba(135, 136, 140, 0.52), rgba(117, 19, 93, 0.73));
	background-repeat: no-repeat;
    background-position: -1px -214px;
    background-size: cover;
}

.bar{
	width: 100% !important;
	background: black;
	
}
.onglet{
	display: flex;
    justify-content: center;
}
/*.nav-btn:hover{
	color:rgb(231, 202, 15) !important;
}*/
 
</style>