<template>
  <div>
   
    <div class="confirmation_modifie_AcceptSuscrib" v-show="frameAcceptSuscrib">
      <div>
       
        <div class="conteneur-souscrire">
          <h4 class="text-center fw-bold m-0">Voulez-vous souscrire à ce package ? 
            <br> Cette action est irréversible !</h4>
          <div class="text-center">
            <button
            class="btn bg-info mx-3 text-white" 
            @click.prevent="SubscribePackage"
          >
            <span v-if="!turnSpinner">Oui</span>  <div class="spinner" v-if="turnSpinner"></div>
          </button>
          <button class="btn bg-danger mx-3 text-white" @click.prevent="notAccept">
            Retour
          </button>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation_modifie" v-show="showChoosePart">
      <div class="card">
        <div class="info-package-name">
       
          <h2 class="text-center fw-bold text-light">Nombre d'articles</h2>
       <span  @click.prevent="closeFrame">
         x
       </span>
     </div>
        
        <div class="part-btn-souscrire">
          <div v-if="typeFournisseur == 1" class="container-btn-susbcrire">
            <button class="btn-susbcrire gray">
             {{numberOfProduct}}
            </button>
          </div>
    <div class="container-btn-susbcrire" v-else>
            <button class="btn-susbcrire"
            :class="numberChoose == item ? 'gray':null"
             v-for="(item,index) in numberTimes" 
             :value="item" 
             :key="index"
             @click.prevent="chooseNumberArticle(item)">
             {{item}}
            </button>
          </div>
          <h4 class="text-center py-1" v-if="numberChoose">Nombre de pièces : <strong>{{numberChoose}}</strong></h4>
            <h4 class="text-center py-1" v-if="priceOfPackageWithNumber">Prix : <strong>{{moneyFormat.format(priceOfPackageWithNumber)}}Fcfa</strong></h4>
          <div class="text-center">
            <button
            :disabled="numberChoose || numberOfProduct ? false:true"
            class="btn bg-info mx-3 text-white" 
            @click.prevent="Subscribe"
          >
            Oui
          </button>
          <button class="btn bg-danger mx-3 text-white" @click.prevent="closeFrame">
            Retour
          </button>
          </div>
        </div>
      </div>
    </div>


    <section class="p0 container-fluid banner about_banner">
      <div class="about_banner_opacity">
        <div class="container">
          <div class="banner_info_about">
            <h1>Details</h1>
           
          </div>
        </div>
      </div>
    </section>
    <section class="shop_container product_details_container faqs_sec">
      <div class="container-fluid">
        <div class="row" v-if="packages !== null">
          <div
            class="col-lg-12 col-sm-12  shop-page-content product-details-page-content"
          >
          <div class="spinnerPackage" v-show="spinnerPackage"></div>
            <div class="row product-details-box">
              <div class="text-center">
                <img v-if="packages.photo"
                      class=" col-lg-6 col-sm-12"
                      :src="URL+packages.photo"
                      :alt="packages.photo"
                  /><img v-else
                    class=" col-lg-6  col-sm-12"
                    src="/images/slides/img1.jpg"
                    alt="invest"
                />
							
							</div>
              <div class="col-lg-6 img-holder">
               
                <h1> Package : {{ packages.libelle }}</h1>
                <span class="badge bg-warning w-25 text-dark" v-if="packages.franchise == 1">Franchise</span>
                <br />
                <h5 class="Coutpackages"
                  >Coût du package :
                  <b
                    >{{
                      packages.cout_acquisition
                      
                    }}
                    fcfa</b
                  ></h5
                >
                
              </div>
              <div class="col-lg-6 col-sm-12" style="padding-left: 23px">
                <h5
                  >Coût de vente :
                  <b>{{  packages.cout_vente }} fcfa</b></h5
                >
                <h5
                  >Gain: <b> {{
                    moneyFormat.format(packages.cout_vente - packages.cout_acquisition) 
                    
                    }} fcfa</b></h5
                >
                <h5
                   v-if="this.packages.nb_jours">Délai d'écoulement: <b>{{ packages.nb_jours }} jours</b>
                </h5>
                <h5 v-else>Pas de délai fixé.</h5>
                <h5
                  >Article restant: <b>{{ packages.pieces_restantes }}</b>
                </h5>
                <div v-if="packages.pieces_restantes > 0 ">
                  <a
                    href="javascript:void(0)"
                    class="add-to-cart"
                    @click="openFrame(packages.id,packages.cout_acquisition)"
                    v-show="souscris"
                    >Souscrire<i class="fa fa-arrow-circle-right"></i
                  ></a>
             
                </div>
                
                <div v-else>
                      <div class="alert alert-danger" role="alert">
                        Package acheté
                      </div>
                    </div>
                    <section class="rating" v-if="showCommentaire">
                        <h4>Notez le fournisseur</h4>
                        <span>Etes-vous statisfaire du fournisseur</span>
                        <rate :length="5" v-model="rateValue"  class="viaSlot">
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                          </svg>
                        </rate>
                        <textarea v-if="rateValue >= 3" name="" id="" cols="80" rows="7" v-model="commentaireRate"></textarea>
                        <div class="contenaire_btn-envoyer">
                          <button
                          :disabled="rateValue || commentaireRate ? false:true"
                          :class="rateValue && commentaireRate ? 'bg-warning':null"
                           class="btn-lg bg-secondary"
                           @click="ratePackage"
                           >
                           <span v-if="!sendRateSpinner">Envoyer</span>  <span class="spinner" v-if="sendRateSpinner"></span>
                          </button>
                        </div>
                       
                    </section>
              </div>
            </div>
            <div class="bottom_tab col-xs-12">
              <ul class="nav nav-tabs">
                <li class="active">
                  <a data-toggle="tab" href="#tab_1">Package</a>
                </li>
                <li><a data-toggle="tab" href="#tab_2">Coût d'acquisition</a></li>
                <li><a data-toggle="tab" href="#tab_5">Marge par produit</a></li>
                <li><a data-toggle="tab" href="#tab_3"> Catégorie de package </a></li>
                <li><a data-toggle="tab" href="#tab_4"> Avis des Clients </a></li>
              </ul>
              <div class="tab-content bottom_tab_info">
                <div id="tab_1" class="tab-pane fade in active">
                  <h5>{{ packages.pieces_restantes}} Produits</h5> 
                </div>
                <div id="tab_2" class="tab-pane fade">
                  <h5>{{ moneyFormat.format(packages.cout_acquisition) }} fcfa</h5>
                </div>
                <div id="tab_3" class="tab-pane fade">
                  <h5 class="first_letter">{{ packages.type.libelle }}</h5>
                </div>
                <div id="tab_5" class="tab-pane fade">
                  <h5 class="first_letter">{{ moneyFormat.format(packages.gain_par_piece) }} Fcfa</h5>
                </div>
                <div id="tab_4" class="tab-pane fade avis">
                  <h4 class="fw-bold first_letter">{{ packages.type.libelle }}</h4>
                  <h1>{{value}} / 5</h1>
                  <div>
                    <rate :length="5" v-model="value"  class="viaSlot" :readonly="true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                      </svg>
                    </rate>
                   
                  </div>
                  <span>{{ packages.rate.length }} Commentaires</span>
                  <div>
                    <div class="block_cont" v-for="(item,index) in paginatedData" :key="index" >
                      <div class="cont_black">
                  
                       <div>
                        <span>{{new Date(item.created_at).toLocaleDateString('fr')}}</span>
                        <h5>{{item.user.nom}} {{item.user.prenoms}}</h5>
                       </div>
                       
                      </div>
                      <div class="cont-para">
                      
                          
                            <rate :length="5" :value="item.rate"  class="viaSlot" :readonly="true">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                              </svg>
                            </rate>
                        
                   
                        <p>{{item.commentaire}}</p>
                      </div>
                     
                    </div>
                   
                  </div>
                  <ul class="d-flex justify-content-center conteneur_pagination " v-if="paginatedData.length">
                    <li class="mx-3">
                  <button  class="page next-prev" :class="isInFirstPage? 'null':'prev'" @click="onClickFirstPage" :disabled="isInFirstPage"  >
                    &laquo;
                  </button>
                   </li>
                    <li>
                    <button class="pagination_btn page mx-3 "  v-for="(page,index) in pages" :key="index" @click="onClickPage(page.number)" 
                    :class="{color:isPageActive(page.number)}"
                    > {{page.number}}  
                    </button>
                    </li>
                    <li class="mx-3">
                    <button class="page next-prev" :class="isInLastPage ? 'null':'prev'"  @click="onClickNextPage" :disabled="isInLastPage" >
                    &raquo;
                    </button>
                    </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="row" v-else>
          <div
          class="col-lg-12 col-sm-12  pull-right shop-page-content product-details-page-content"
        >
          <div class="row product-details-box">
            <div class="text-center" >
              
              <div class="text-center" >
                <img 
                    class="img-responsive col-lg-6  col-sm-12"
                    src="/images/slides/Dorian Gray SW 7017 - Neutral Paint Color - Sherwin-Williams.jfif"
                    alt=""
                />
							
							</div>
            </div>
            <div class="col-lg-6 img-holder" style="padding-left: 23px">
              <h1> Package : </h1>
              <br />
              <h5 class="Coutpackages"
                >Coût du package :
                </h5
              >
            </div>
            <div class="col-lg-6 col-sm-12" style="padding-left: 23px">
              <h5
                >Coût de vente :
                </h5
              >
              <h5
                >Gain: </h5
              >
              <h5
                >Délai d'écoulement: 
              </h5>
              <h5
                >Article restant: 
              </h5>
              <div>
                <a
                  href="javascript:void(0)"
                  class="add-to-cart"
                  @click="openFrame(packages.id)"
                  v-show="souscris"
                  >Souscrire<i class="fa fa-arrow-circle-right"></i
                ></a>
              </div>
              
              
               
            </div>
          </div>
          
        </div>

        </div>
      </div>
    </section>
  </div>

</template>

<script>
import {StarRating} from 'vue-rate-it';
import {URL} from "@/config";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "show",
  components: {
    StarRating
  },
  data() {
    return {
      URL:URL,
      value:0,
      spinnerPackage:false,
      turnSpinner:false,
      sendRateSpinner:false,
      priceOfPackageWithNumber:"",
      priceOfPackage:"",
      showChoosePart:false,
      typePackages: null,
      packages: null,
      user: JSON.parse(localStorage.getItem('user')),
      packageId: "",
      souscris: true,
      
      moneyFormat: new Intl.NumberFormat("de-DE"),
      idPackage:"",
      nb_products:"",
      numberChoose:"",
      frameAcceptSuscrib:false,
      numberTimes:[],
      sellPackages:[],
      showCommentaire:false,
      rateValue:"",
      commentaireRate:"",
      perPage:3,
      currentPage:1,
      current:1,
      totalPages:"",
      maxVisibleButtons:3,
      haveYouPermissionForNote:"",
      nameProduit :"", 
        numberOfProduct :"", 
          typeFournisseur :"",
    };
  },
  computed: {
    startPage(){
            if(this.currentPage === 1) return 1;
            if(this.currentPage === this.totalPages)return this.totalPages - this.maxVisibleButtons + (this.maxVisibleButtons-1);
            return  this.currentPage - 1;
        },
        endPage(){
            return Math.min(this.startPage + this.maxVisibleButtons-1 , this.totalPages)
        },
        pages(){
            let range =[]
            for (let i = this.startPage; i <= this.endPage ; i++) {
                range.push({ number:i, isDisabled: i === this.currentPage
                });
            }
            return range;
        },
        isInFirstPage(){
            return this.currentPage === 1
        },
        isInLastPage(){
            return this.currentPage === this.totalPages
        },
    paginatedData(){
         let start = (this.currentPage * this.perPage) - this.perPage;
            let end = start + this.perPage;
         return this.packages.rate.slice(start,end);
    },

  },
  methods: {
    onPageChange(page) {
    this.currentPage = page;
    },
    onClickFirstPage(){
           this.onPageChange(this.currentPage-1)
        },
         onClickPreviousPage(){
          
           this.onPageChange(this.currentPage-1)
        },
         onClickPage(page){
        this.onPageChange(page)
        },
         onClickNextPage(){
          
          this.onPageChange(this.currentPage + 1)
        },
         onClickLastPage(){
          this.onPageChange(this.totalPages) 
        },
         isPageActive(page){
           return this.currentPage === page
        },
    verfClient(){
      if(this.sellPackages.includes(this.haveYouPermissionForNote)){
        this.showCommentaire = !this.showCommentaire
      }
    },
    chooseNumberArticle(number){
    this.numberChoose = number
    this.priceOfPackageWithNumber = this.priceOfPackage * number
    // console.log("this.numberChoose", this.numberChoose)
    },
    closeFrame(){
      this.idPackage = ""
      this.priceOfPackageWithNumber = ""
      this.nb_products = ""
      this.numberTimes = []
      this.numberChoose = ""
    this.showChoosePart = !this.showChoosePart;  
  
    },
    openFrame(id,pricePackage=1){
      this.idPackage = id
      this.priceOfPackage = pricePackage
      this.nameProduit = this.packages.libelle
          this.numberOfProduct = this.packages.franchise ? this.packages.nb_products : null
          this.typeFournisseur = this.packages.franchise
       
          if(!this.packages.franchise){
            for (let i = this.packages.tranche; this.packages.pieces_restantes >= i; i+=this.packages.tranche){
            this.numberTimes.push(i)
           //console.log('NOMBRE FOIS',i)
          }
          }
     
    
      this.showChoosePart = !this.showChoosePart;  
    },
    getPackages() {
      let app = this;
      this.spinnerPackage = true;
      axios
        .get("api/packages/" + this.$route.params.id)
        .then((res) => {
          app.packages = res.data.data;
          this.value = this.packages.rate_count
          this.packages.sell.forEach(item=>{
            this.sellPackages.push(item.user_id)
          })
          this.totalPages = Math.ceil(this.packages.rate.length / 3)
          this.verfClient()
          //console.log("this.sellPackages", this.packages);
          this.spinnerPackage = false;
        })
        .catch((error) => {
          //console.log("Qdfe", error);
        });
    },

    getTypePackages() {
      let app = this;
      axios.get("api/types").then((res) => {
        //console.log("Types", res);
        app.typePackages = res.data.data;
      })
      .catch((error) => {
        //console.log(error)
      })
    },
    Subscribe(){
      this.frameAcceptSuscrib = !this.frameAcceptSuscrib
    },
    notAccept(){
      this.frameAcceptSuscrib = !this.frameAcceptSuscrib
    },
    SubscribePackage() {
   
      if (!this.user) {
        this.$router.push('/auth')
      }else{
        this.turnSpinner = true
        axios
            .post("api/achats", {
              user_id: this.user.id,
              package_id: this.idPackage,
              nb_pieces: this.numberChoose,
            })
            .then((res) => {
              //console.log("ACTIVE PACKAGES", res.data.data);
              
              if (res.data.status == 'true') {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Souscription effectuée avec succès",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.packages.pieces_restantes = res.data.data.pieces_restantes
                this.idPackage = ""
         this.nb_products = ""
        this.numberTimes = []
        this.numberChoose = ""
       this.showChoosePart = !this.showChoosePart; 
       this.frameAcceptSuscrib = !this.frameAcceptSuscrib
       this.turnSpinner = false
              }else{
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.turnSpinner = false
              }
            })
            .catch((error) => {
              //console.log(error);
              this.turnSpinner = false
            });
      
      }
      
      
    },
    ratePackage() {
      let commentaireRate =  this.commentaireRate ? this.commentaireRate:null;
     let data = {
      package_id: this.packages.id,
          rate: this.rateValue,
          commentaire: commentaireRate,
     }
     this.sendRateSpinner = true
     //console.log("DATA",data)
      axios.post("api/rate", {
          package_id: this.packages.id,
          rate: this.rateValue,
          commentaire: this.commentaireRate,
        })
        .then((res) => {
          //console.log("ACTIVE PACKAGES", res);
      
          if (res.data.status === true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Merci pour l'avis donné.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.sendRateSpinner = false
             this.rateValue = ""
           this.commentaireRate = ""
          }else{
            Swal.fire({
              position: "center",
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.sendRateSpinner = false
          }
        })
        .catch((err) => {
          this.sendRateSpinner = false 
          Swal.fire({
              position: "center",
              icon: "info",
              title: err.errors.package_id[0],
              showConfirmButton: false,
              timer: 1500,
            });
        })
    },
  },

  mounted() {
    this.getPackages();
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = JSON.parse(localStorage.getItem('user')).data
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    this.haveYouPermissionForNote = this.infoUserWithData ? this.infoUserWithData.id : this.infoUserWithoutData.id
    //console.log("USER", this.user.id);
    this.getTypePackages();
  },
};
</script>

<style scoped>
.product-details-page-content{
  position:relative;
}
.spinnerPackage {
  border: 5px solid #ccc; 
  border-top: 5px solid #ff6a00; 
  border-radius: 50%; 
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position:absolute;
  margin-left:50%;
  transform:translateX(-50%);
  margin-top: 10%;

}
.d-flex{
  display:flex;
  justify-content:center;
  align-items:center;
}
.prev{
  background:rgb(227, 167, 17);
  color:white !important;
}
.pagination_btn{
  border:1px solid white;
  
}
.color{
  background:rgb(227, 167, 17);
  color:white !important;
  font-weight: bold !important;
}
.page{
  width:30px;
  height:30px;
  border-radius:10px;
}
.next-prev{
  border:none;
  color:rgb(227, 167, 17);
  font-weight: bold !important;
}
.block_cont{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  margin:1em 0;
}
.cont-para{
  padding:0 0 0 2em;
}
.cont_black{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  height:auto;
}
.product-details-page-content .bottom_tab .tab-content p{
  padding:0;
  margin:0;
}
.cont_black .img{
  width:30px;
  height:30px;
  background-color:rgb(160, 160, 160);
  border-radius: 100%;
}
.block_cont h6{
  flex:2;
  background: gray;
  width:200px;
  height:70px;
  line-height: 70px;
  border-radius: 100%;
}
.btn-lg{
  margin:0 1em !important;
}
.contenaire_btn-envoyer{
  text-align: left;
}
.avis{
  padding: 0 1em;
}
h5{
  font-size: 1.2em;
  padding: .5em;
}
.gray,.bg-warning{
  background-color:rgb(244, 179, 0) !important;
  color: white;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  overflow-y:scroll;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
  font-weight: bold;
}
.confirmation_modifie_AcceptSuscrib{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
 
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
  font-weight: bold;
}
.conteneur-souscrire{
  background: white;
  padding:1em;
  width:300px;
  transform:translateX(-50%);
  margin-left:50%;
}
.part-btn-souscrire{
  padding:5.5em 0 1em 0;
}
.info-package-name span{
  position:absolute;
  right:.5em;
  top:.8em;
  color:red;
  font-size:1.2em;
  cursor: pointer;
  box-shadow: 1px 1px 6px rgb(255, 255, 255) inset;
  width:30px;
  height: 30px;
  border-radius:100%;
}
.card {
  width:90%;
  position:relative;
  height:350px;
  overflow-y:scroll;
  border-radius: 5px;
  background-color: white;
  font-weight: bold;
}
.info-package-name{
  position:fixed;
  width:inherit;  
  padding:.5em 0 0 0;
  text-align:center;
  text-transform: capitalize;
  background:rgb(0, 0, 0);
  box-shadow: 1px 1px 6px rgba(241, 241, 241, 0.54);
}
.btn-susbcrire{
  width:auto;
  padding:0 1em;
  margin:.5em;
  height: 30px;
  background-color: grey;
  border:1px solid rgba(128, 128, 128, 0.187);
  border-radius: 5px;
  font-weight: bold;
}
.container-btn-susbcrire{
  padding:1em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
}
.bg-info{
  background-color:rgb(244, 179, 0);
  color:white;
  font-weight: bold;
}
.bg-danger{
  background-color:rgb(255, 0, 0);
  color:white;
}
.product-details-box-loading{
  height: 300px;
  width:100%;
  background-color:gray;
}
.m-0{
  margin:0;
}
.mx-2{
  margin:0 1em;
}
.mx-3{
  margin:0 .5em;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #0d6efd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
