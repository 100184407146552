<template>
  <div>
    <div class="spinner" v-show="spinner"></div>
    <h1 class="fw-bold text-center">Mes investissements</h1>
    <table class="table gtable">
      <thead class="thead-dark tabl">
        <tr>
          <th scope="col">Date de souscription</th>
          <th scope="col">Packages</th>
          <th scope="col">Franchise</th>
          <th scope="col">Coût du package (Fcfa)</th>
         
          <th scope="col">Date d'échéance</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in souscription" :key="index">
          <td>{{ new Date(item.created_at).toLocaleDateString('fr') }}</td> 
          <td class="first_letter">{{ item.package.libelle }}</td>
          <td>
            <span v-if="item.seller.franchise == 1">Oui</span>
            <span v-if="item.seller.franchise == 0">Non</span>
          </td>
          <td>
            {{ moneyFormat.format(item.package.cout_acquisition * item.nb_pieces) }}
          </td>
        
          <td>{{ item.date_validite }}</td>
          
          <td>
            <router-link :to="{ name: 'Details', params: { id: item.id } }"
              >
             <button>Détail</button></router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
</template>

<script>
import axios from "axios";
import moment from "moment";
moment.locale("fr");
export default {
  name: "souscription",
  components: {},
  data() {
    return {
      souscription: [],
      spinner:false,
      user: JSON.parse(localStorage.getItem('user')),
      moneyFormat: new Intl.NumberFormat("de-DE"),
    };
  },

  methods: {
    getsubscribe() {
      //console.log("this.user.id",this.user.id)
      this.spinner=true
      axios
        .get(
          "api/user_achats/" + this.user.id
        )
        .then((res) => {
          //console.log("RES",res)
          res.data.data.forEach((element) => {
            element.date_validite = moment(element.date_validite).format(
              "DD/MM/YYYY"
            );
          });
          this.souscription = res.data.data;
          this.spinner=false
          //console.log("SUBSCRIBEsouscription", res);
        });
    },
  },

  mounted() {
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = JSON.parse(localStorage.getItem('user')).data
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    //console.log("USER", this.user.id);
    this.getsubscribe();

    axios.get("api/sellsByInvestor/" + this.user.id)
    .then(response => {
      //console.log("sellsByInvestor",response)
    })
  },
};
</script>

<style scoped>
.spinner {
  border: 5px solid #ccc; /* Taille et couleur des bordures */
  border-top: 5px solid #ff6a00; /* Couleur de la bordure supérieure */
  border-radius: 50%; /* Arrondir les coins pour créer un cercle */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation de rotation */
  position:absolute;
  margin-left:50%;
  transform:translateX(-50%);
  margin-top: 10%;

}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

td,th{
  font-weight:bold;
  text-align: center;
}

.fw-bold{
font-weight:bold;
}
.ls {
  border-style: solid;
  text-align: center;
}
.tabl {
  background-color: black;
  color: white;
}

.gtable {
  padding: 50px;
  border: 2px solid gray;
  margin-bottom: 50vh;
  margin-top: 30px;
}
</style>
