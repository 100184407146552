<template>

    <div>
      <div class="spinner" v-show="spinner"></div>
      <h1 class="fw-bold text-center">Mes opérations</h1>
        <table class="table gtable">
            <thead class="thead-dark tabl">
                <tr class="text-center">
                <th scope="col">Type de l'opération</th>
                <th scope="col">Montant (Fcfa)</th>
                <th scope="col">Pièce jointe</th>
                <th scope="col">Date d'enregistrement</th>
                <th scope="col">Statut</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in souscription" :key="index">
                <td class="first_letter">{{ item.type }}</td>
                <td>
                    {{moneyFormat.format(item.amount) }}
                </td>
                <td>
                    <button class="btn" data-toggle="modal" data-target="#exampleModalCenter" @click="imageProof =item.proof ">voir l'image</button>
                    
                </td>
                <td>{{ item.created_at }}</td>
                <td v-if="item.state == 'validé' ">
                    <span class="badge badge-success" style="background-color: green;">{{ item.state }}</span>
                </td>
                <td v-else>
                    <span class="badge badge-success" style="background-color: red;">{{ item.state }}</span>
                </td>
                </tr>
            </tbody>
            </table>
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <img :src="URL+imageProof"  width="100%" alt="">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
    </div>
    
    
  </template>
  
  <script>
  import axios from "axios";
  import moment from "moment";
  import {URL} from "@/config";
  moment.locale("fr");
  export default {
    name: "souscription",
    components: {},
    data() {
      return {
        souscription: {},
        user: JSON.parse(localStorage.getItem('user')),
        imageProof:null,
        spinner:false,
        moneyFormat: new Intl.NumberFormat("de-DE"),
        URL:URL
      };
    },
  
    methods: {
      getsubscribe() {
        this.spinner = true;
        axios
          .get(
            "api/user-operations"
          )
          .then((res) => {
            res.data.data.forEach((element) => {
            element.created_at = moment(element.created_at).format(
              "DD/MM/YYYY"
            );
          });
            this.souscription = res.data.data;
            this.spinner = false;
            //console.log("SUBSCRIBE", res);
          });
      },
    },
  
    mounted() {
      
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = JSON.parse(localStorage.getItem('user')).data
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    //console.log("USER", this.user.id);
      this.getsubscribe();
    },
  };
  </script>
  
  <style scoped>
  .spinner {
    border: 5px solid #ccc; /* Taille et couleur des bordures */
    border-top: 5px solid #ff6a00; /* Couleur de la bordure supérieure */
    border-radius: 50%; /* Arrondir les coins pour créer un cercle */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animation de rotation */
    position:absolute;
    margin-left:50%;
    transform:translateX(-50%);
    margin-top: 10%;
  
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  td,th{
    font-weight:bold;
    text-align: center;
  }
.fw-bold{
  font-weight:bold;
}
  .tabl {
    background-color: black;
    color: white;
  }
  
  .gtable {
    padding: 50px;
    border: 2px solid gray;
    margin-bottom: 50vh;
    margin-top: 30px;
    overflow: auto;
    width: 100%;
  }
  </style>
  