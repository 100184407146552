import VueRouter from 'vue-router'
import Home from './views/home.vue'
import index from './views/index.vue'
import show from './views/show.vue'
import Auth from './views/Auth.vue'
import contact from './views/contact.vue'
import about from './views/about.vue'
import profil from './views/profil.vue'
import souscription from './views/souscription.vue'
import Operations from './views/operations.vue'
import operation from './views/operation.vue'
import details from './views/details.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/packages-index',
        name: 'index',
        component: index
    },

    {
        path: '/ShowPackages/:id',
        name: 'show',
        component: show
    },

    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    },

    {
        path: '/profil/',
        name: 'Profil',
        component: profil
    },

    {
        path: '/souscription',
        name: 'Souscription ',
        component: souscription
    },
    {
        path: '/operations',
        name: 'Operations ',
        component: Operations
    },
    {
        path: '/details/:id',
        name: 'Details',
        component: details
    },

    {
        path: '/operation',
        name: 'operation ',
        component: operation
    },

    {
        path: '/contact',
        name: 'Contact',
        component: contact
    },

    {
        path: '/about',
        name: 'About',
        component: about
    },

    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (About.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ('../views/AboutView.vue')
    // }
]

const router = new VueRouter({
    history: true,
    mode: "history",
    routes,
    scrollBehavior(_to, _from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiredAuth)) {

        let token = localStorage.getItem('token')

        if (token) {
            next();
        } else {
            next("/login")
        }

    } else {
        next() // assurez vous de toujours appeler `next()` !
    }
})

export default router;