<template>
  <div>
    <vueper-slides infinite="true" fixed-height="500px" autoplay ref="myVueperSlides" :parallax="parallax" :parallax-fixed-content="parallaxFixedContent">
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :image="slide.image"
        :content="slide.content">
        
      </vueper-slide>
    </vueper-slides>
    <section class="welcome_sec">
      <div class="container">
        <div class="row welcome_heading">
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
            <h2>Nous vous offrons<br />des opportunités pour investir</h2>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-7 col-xs-12">
            <p>
              Avec Invest <br />
              investissez librement <br />
              en toute sécurité.
            </p>
          </div>
        </div>
        <div class="row welcome welcome_details">
          <div class="col-lg-6 col-md-12">
            <div class="">
              <img src="../slides/img8.jpg" alt="images" class="imgOpp" />
              <div class="welcome_info">
                <h3>Publications des opportunités</h3>
                <p>
                  Vous avez plusieurs opportunités <br />
                  saisissez-les afin de faire fructifier votre argent.
                </p>
              </div>
            </div>
            <div class="welcome_item welcome_item_bottom">
              <img src="../slides/img9.jpg" alt="images" class="imgOff" />
              <div class="welcome_info">
                <h3>Réception des offres publiées</h3>
                <p>Ne laissez pas passer ces offres uniques et investissez dès maintenant.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 bottom_row">
            <div class="welcome_item">
              <img src="../slides/img10.jpg" alt="images" class="imgAchat" />
              <div class="welcome_info">
                <h3>Achat du produit</h3>
                <p>Achetez le produit de vos choix.</p>
              </div>
            </div>
            <div class="welcome_item welcome_item_bottom">
              <img
                src="../slides/img11.jpg"
                alt="images"
                class="imgInvest"
              />
              <div class="welcome_info">
                <h3>Investissement et Intérêt</h3>
                <p>
                  Investissez en toute sécurité et toute confiance afin
                  d'obtenir des intérêts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="we_are">
      <div class="left_side float_left">
        <div class="we_are_opacity">
          <div class="we_are_border">
            <h2>Avec INVEST ,investissez librement.</h2>
          </div>
        </div>
      </div>
      <div class="right_side float_right">
        <div class="we_are_deatails">
          <h2>Invest</h2>
          <p>
            Est une plateforme de mise en relation entre les investisseurs et les
            entreprises désirant faire un rabais sur stock pour avoir de la
            trésorerie ou une bonne capacité de stockage puis le vendre et
            reverser une marge aux investisseurs...
          </p>
          <div class="list_item">
            <img src="images/icone6.jpg" alt="invest" class="imgCon6" />
            <p>Meilleur plateforme<br />d'investissement</p>
            <img src="images/icon-4.jpg" alt="invest" class="imgCon" />
            <p>L'innovation<br />au rendez-vous</p>
            <p class="support"><br /></p>
          </div>
        </div>
      </div>
    </section>
    <section class="testimonial_sec clear_fix">
			<div class="container">
				<div class="row testimonial_package">
					<div  class="col-lg-5 col-md-12 col-sm-12 col-xs-12 testimonial_container" >
						<div class="sec-title">
							<h2>Packages</h2>
						</div>
           <div>
            <vueper-slides infinite="true" fixed-height="500px" autoplay ref="myVueperSlides">
              <vueper-slide
                v-for="(silde , i) in packages"
                :key="i"
                >
                <template #content>
                  <img class="round_img" :src="'https://backend.invest-ci.com/public'+ silde.photo" :alt="silde.type.photo">
                  <div>
										<h3 class="first_letter text-left">{{silde.type.libelle}}</h3>
										<h5 class="text-left first_letter">{{silde.libelle}}</h5>
										
									</div>
                  <div>
                    <ul class="pack">
                      <li>
                        <i class="fa fa-arrow-circle-right"></i> Coût d'acquisition:
                        {{ moneyFormat.format(silde.cout_acquisition) }} FCFA
                      </li>
                      <li>
                        <i class="fa fa-arrow-circle-right"></i> Coût de vente :{{
                          moneyFormat.format(silde.cout_vente)
                        }}
                        FCFA
                      </li>
                      <li>
                        <i class="fa fa-arrow-circle-right"></i> Gain:
                        {{ moneyFormat.format(silde.cout_vente-silde.cout_acquisition) }} FCFA
                      </li>
                      <li v-if="!silde.franchise">
                        <i class="fa fa-arrow-circle-right"></i> Delai d'écoulement:
                        {{ silde.nb_jours }} jours
                      </li>
                    </ul>
                  </div>
                  <button  class="btn-lg bg-warning">
                    <router-link to="/packages-index">Voir plus +</router-link> 
                    </button>
                 </template>
              </vueper-slide>
            </vueper-slides>
           </div>
            <!-- <div  class="owl-carousel loop owl-theme owl-loaded testimonial">
             
							<div v-for="(item, index) in packages" :key="index" >
             
								<div class="testimonial d-flew flex-row">									
									<img class="round_img" 
                  :src="'https://backend.invest-ci.com/public'+item.photo" :alt="item.type.photo">
									<div>
										<p class="first_letter text-left">{{item.type.libelle}}</p>
										<p class="text-left">{{item.libelle}}</p>
										
									</div>
								</div>
                <div>
                  <ul class="pack">
                    <li>
                      <i class="fa fa-arrow-circle-right"></i> Coût d'acquisition:
                      {{ moneyFormat.format(item.cout_acquisition) }} FCFA
                    </li>
                    <li>
                      <i class="fa fa-arrow-circle-right"></i> Coût de vente :{{
                        moneyFormat.format(item.cout_vente)
                      }}
                      FCFA
                    </li>
                    <li>
                      <i class="fa fa-arrow-circle-right"></i> Gain:
                      {{ moneyFormat.format(item.cout_vente-item.cout_acquisition) }} FCFA
                    </li>
                    <li v-if="!item.franchise">
                      <i class="fa fa-arrow-circle-right"></i> Delai d'écoulement:
                      {{ item.nb_jours }} jours
                    </li>
                  </ul>
                </div>
                <button  class="btn-lg bg-warning">
                  <router-link to="/packages-index">Voir plus</router-link> 
                  </button>
							</div>
						</div> -->
					</div>
					<div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 company">
						<h2>Nos valeurs</h2>
						<div class="company-tab">
							  <ul class="nav nav-pills nav-justified">
							    <li class="active"><a data-toggle="pill" href="#menu1">Qualité</a></li>
							    <li><a data-toggle="pill" href="#menu2">Engagement</a></li>
							    <li><a data-toggle="pill" href="#menu3">Succès</a></li>
						  	</ul>
							 <div class="tab-content">
							    <div id="menu1" class="tab-pane fade in active">
							      <h3>La qualité de notre entreprise</h3>

							      <ul class="p0">
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">Croissance de notre entreprise</a></li>
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">1000 employés</a></li>
							      
							      </ul>
							      <ul class="list-two">
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">Croissance de notre entreprise</a></li>
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">1000 employés</a></li>
							      
							      </ul>
							    </div>
							    <div id="menu2" class="tab-pane fade">
							      <h3>L'engagement de notre entreprise</h3>
							      
							      <ul class="p0">
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">Croissance de notre entreprise</a></li>
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">1000 employés</a></li>
							      
							      </ul>
							      <ul class="list-two">
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">Croissance de notre entreprise</a></li>
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">1000 employés</a></li>
							      
							      </ul>
							    </div>
							    <div id="menu3" class="tab-pane fade">
							      <h3>Le succès de notre entreprise</h3>
							      
							      
							      <ul class="list-two">
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">Croissance de notre entreprise</a></li>
							      	<li><i class="fa fa-arrow-circle-right"></i><a href="#">1000 employés</a></li>

							      </ul>
							    </div>
							 </div>
						</div>
					</div>
				</div>
			</div>
		</section>
    <!-- <section class="container-fluid partner_touch_sec">
      <div class="container">
        <div class="row">
          <div class="our_partner col-lg-12 col-sm-12">
            <h2>Nos Partenaires</h2>
            <p></p>
            <ul>
              <li>
                <a href="#"><img src="images/c-icon1.jpg" alt="image" /></a>
              </li>
              <li>
                <a href="#"><img src="images/c-icon2.jpg" alt="image" /></a>
              </li>
              <li>
                <a href="#"><img src="images/c-icon3.jpg" alt="image" /></a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#"><img src="images/c-icon4.jpg" alt="image" /></a>
              </li>
              <li>
                <a href="#"><img src="images/c-icon5.jpg" alt="image" /></a> 
              </li>
              <li>
                <a href="#"><img src="images/c-icon6.jpg" alt="image" /></a>
              </li>
            </ul>
          </div>
          
        </div>
      
      </div>
    
    </section> -->
  </div>
</template>

<script>
import axios from "axios";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: "Home",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      packages: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      typePackages: null,
      parallax: 1,
  parallaxFixedContent: false,
      slides: [
  {
    title: `  
    <h1>Faites des bénéfices</h1>`
    ,
    content:`
    <h3>Investissez dès maintenant 
      <br/> pour préparer votre avenir financier</h3>
    `,
    image: require('../slides/img3.webp')
  },
  {
    title: `  
    <h1>Faites confiance à notre expertise</h1>`,
    content: `
  <h3>Investir
     avec nous  c'est choisir 
     la sécurité <br/> et la rentabilité pour votre argent</h3>
    `,
    image: require('../slides/img5.jpg')
  },
  {
    title: `  
    <h1>Faites confiance à notre expertise</h1>`,
    content: `
  <h3>Vous avez plusieurs opportunités <br />
    saisissez-les afin de faire fructifier votre argent !!</h3>
    `,
    image: require('../slides/img6.jpg')
  },
  
 
]
    };
  },

  methods: {
    
    getPackages() {
     
      axios
      .get("api/publies")
        .then((res) => {
          res.data.data.forEach(item=>{
            if(item.pieces_restantes > 0){
              this.packages.push(item)
            }
          })
          // console.log("DATAPublizs",this.packages);
        })
        .catch((error) => {
          //console.log("Qdfe", error);
        })
    },

    getTypePackages() {
      let app = this;
      axios.get("api/types")
      .then((res) => {
        //console.log("Types", res);
        app.typePackages = res.data.data;
      })
      .catch(err=>{
        //console.log(err)
      })
    },
  },
  mounted() {
    this.getPackages();
    this.getTypePackages();
  },
};
</script>

<style scoped>
.round_img{
  width:100%;
  height:250px;
  object-fit: cover;
}
.vueperslide{
  padding:0 1em;
  border-radius: 25px;
}
.testimonial{
  padding:0 .5em;
}


.contact-us{
  margin:0 -2em;
}

.navbar-nav {
  justify-content: space-between !important;
}
.imgOpp {
  width: 30% !important;
  height: 50% !important;
}
.imgOff {
  width: 30% !important;
  height: 100% !important;
}
button{
  background-color:rgb(255, 162, 0) !important;
}
button a{
  color:black !important;
  font-weight: 400 !important;
}
.imgAchat {
  width: 30% !important;
  height: 100% !important;
}
.imgInvest {
  width: 30% !important;
  height: 100% !important;
}

.imgCon {
  width: 10% !important;
  height: 10% !important;
  border-radius: 40%;
}
.imgCon6 {
  width: 15% !important;
  height: 20% !important;
  border-radius: 20%;
}
.pack {
  font-size: 16px;
  color: black;
  text-align:left
}
.text-left{
  text-align:left;
}
.onglet {
  text-align: center;
}

</style>
