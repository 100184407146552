<template>
  <div class="container-fluid">
    <div class="row main-content bg-white text-center">
      <div class="ConInsc">
        <div class="tab-content">
          <div class="tab-pane active" id="signin">
            <section class="section-tuto" v-show="toogleTuto">
              
              <div class="conteneur-tuto">
                <button class="close-tuto" @click.prevent="toogleTuto = false">Fermer le tutoriel</button>
                <vueper-slides
                  fade
                  infinite="true"
                  fixed-height="500px"
                  autoplay
                >
                  <vueper-slide v-for="(silde, i) in imageTuto" :key="i">
                    <template #content>
                      <div class="conteneur-image">
                        <img
                          class="round_img"
                          :src="silde.image"
                          :alt="silde.image"
                        />
                      </div>
                      <h3 class="fw-bold">{{ silde.title }}</h3>
                      <h5>{{ silde.content }}</h5>
                    
                    </template>
                  </vueper-slide>
                </vueper-slides>
              </div>
            </section>
            <section class="contact_us_container">
              <div class="container">
                <div class="">
                  <div style="text-align:center;" v-if="connex">
                    <h2>Connexion</h2>
                  </div>
                  <div v-else>
                    <h2>Inscription</h2>
                  </div>
                  <div
                    style="text-align:center;"
                    class="form_holder text-center"
                  >
                    <form
                      v-if="connex"
                      class="contact-form"
                      @submit.prevent="handleSubmit"
                    >
                      <input
                        class="form-control email"
                        type="email"
                        v-model="email"
                        name="email"
                        placeholder=" Email"
                      />
                      <input
                        class="form-control name"
                        type="password"
                        v-model="password"
                        name="name"
                        placeholder="Mot de passe"
                      />

                      <Preloader v-if="load" color="red" scale="0.3" />
                      <button v-else type="submit" class="submit">
                        Se connecter <i class="fa fa-arrow-circle-right"></i>
                      </button>
                      <div class="mt-1">
                        <span>Vous n'avez pas de compte ? </span>
                        <a
                          href="#"
                          class="lost-link d-block inscription"
                          @click.prevent="seeForm"
                          v-if="recovery"
                        >
                          Inscrivez-vous</a
                        >
                      </div>
                    </form>
                    <div v-if="!connex">
                      <div class=" container">
                        <div
                          style="text-align:center;"
                          class="form_holder text-center"
                        >
                          <form class="contact-form">
                            <input
                              class="form-control "
                              type="text"
                              v-model="nom"
                              placeholder="Nom"
                            />
                            <input
                              class="form-control "
                              type="text"
                              v-model="prenoms"
                              placeholder="Prénoms"
                            />
                            <input
                              class="form-control "
                              type="text"
                              v-model="email"
                              placeholder="Email"
                            />
                            <input
                              class="form-control "
                              type="text"
                              v-model="phone"
                              pattern="^[0-9]{10}$"
                              placeholder="Téléphone"
                            />
                            <h5
                              class="text-left text-danger"
                              v-if="
                                phone.length > 10 && !phone.match(/^[0-9]{10}$/)
                              "
                            >
                              Veuillez rentrer 10 chiffres
                            </h5>
                            <input
                              class="form-control "
                              type="text"
                              v-model="lieu_habitation"
                              placeholder="Lieu d'habitation"
                            />
                            <input
                              class="form-control "
                              type="text"
                              v-model="numberAccountBank"
                              placeholder="Numéro de carte bancaire (facultatif)"
                            />

                            <div class="conteneur_password">
                              <input
                                class="form-control "
                                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                                type="password"
                                id="password"
                                v-model="password"
                                placeholder="Mot de passe (ex:Mon_mot_de_passe_2023)"
                              />

                              <svg
                                v-if="toogleMyPassword"
                                @click="seeMyPassword('password')"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-eye seePassword"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                />
                                <path
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                />
                              </svg>

                              <svg
                                v-if="!toogleMyPassword"
                                @click="seeMyPassword('password')"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-eye-slash seePassword"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                                />
                                <path
                                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                                />
                                <path
                                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                                />
                              </svg>
                            </div>
                            <h5
                              class="text-left text-danger"
                              v-if="
                                (password > 8 &&
                                  !password.match(
                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
                                  )) ||
                                  !password.match(
                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
                                  )
                              "
                            >
                              Le mot de passe doit comporter au moins 8
                              caractères avec :

                              <br />
                              . au moins 1 chiffre <br />
                              . une lettre majuscule <br />
                              . une lettre miniscule <br />
                              . un caractère spécial
                            </h5>
                            <div class="conteneur_password">
                              <input
                                class="form-control name"
                                type="password"
                                v-model="password_confirmation"
                                id="confirmation"
                                placeholder="Confirmation de Mot de passe"
                              />

                              <svg
                                @click="seeMyPassword('confirmation')"
                                v-if="!toogleMyCPassword"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                class="bi bi-eye-slash seePassword"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                                />
                                <path
                                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                                />
                                <path
                                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                                />
                              </svg>

                              <svg
                                @click="seeMyPassword('confirmation')"
                                v-if="toogleMyCPassword"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                class="bi bi-eye seePassword"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                />
                                <path
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                />
                              </svg>
                            </div>
                            <h5
                              class="text-left text-danger"
                              v-if="
                                password_confirmation &&
                                  password_confirmation != password
                              "
                            >
                              Mot de passe incorrect
                            </h5>
                            <div class="text-left conteneur_piece">
                              <label class="label">Pièce d'identité</label>
                              <input
                                class="form-file"
                                type="file"
                                @change="handleMedias"
                                placeholder="Mot de passe"
                              />
                            </div>
                            <div></div>

                            <button
                              v-if="!this.loaderSpinner"
                              @click.prevent="middleware"
                              class="submit"
                            >
                              Inscription<i
                                class="fa fa-arrow-circle-right"
                              ></i>
                            </button>
                            <div class="mt-1">
                              <span class="d-block">
                                Déja un Compte ?
                              </span>
                              <a
                                class="btn"
                                href="#"
                                @click.prevent="seeForm"
                                style="color: rgb(90, 166, 225)"
                                >Se connecter</a
                              >
                            </div>
                            <Preloader
                              v-if="this.loaderSpinner"
                              color="red"
                              scale="0.6"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Preloader from "@/components/preloader.vue";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Auth",
  components: {
    Preloader,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      rei: true,
      toogleTuto:false,
      imageTuto: [
        {
          title: "Étape 1 : Créer votre compte",
          content: "Rejoignez notre plateforme en quelques étapes simples",
          image: "/assets/inscrire.jpeg",
        },
        {
          title: "Étape 2 : Recharger votre compte",
          content: `
    Ajoutez des fonds à votre compte pour démarrer votre parcours
     en rechargeant votre compte dans votre espace compte.     `,
          image: "/assets/payment.jpeg",
        },

        {
          title: "Étape 3 :Investir",
          content: `
     Investissez en toute sécurité et toute confiance afin
                  d'obtenir des intérêts                  `,
          image: "/assets/investir.jpeg",
        },
      ],
      toogleMyPassword: true,
      toogleMyCPassword: true,
      connex: true,
      recovery: true,
      nom: "",
      prenoms: "",
      phone: "",
      email: "",
      password: "",
      lieu_habitation: "",
      numberAccountBank: "",
      password_confirmation: "",
      msgError: false,
      showMsg: true,
      confirmation: false,
      loaderSpinner: false,
      proofPieces: "",
    };
  },

  methods: {
    seeMyPassword(idInput) {
      // console.log(idInput);
      let password = document.getElementById(idInput);
      password.type != "text"
        ? password.setAttribute("type", "text")
        : password.setAttribute("type", "password");
      if (idInput != "password") {
        this.toogleMyCPassword = !this.toogleMyCPassword;
      }
      if (idInput != "confirmation")
        this.toogleMyPassword = !this.toogleMyPassword;
    },
    handleMedias(files) {
      this.proofPieces = files.target.files[0];
    },
    seeForm() {
      this.connex = !this.connex;
    },
    seeRecovery() {
      this.recovery = !this.recovery;
    },
    middleware() {
      let middlewarePhone = /^\d{10}$/;

      if (
        !this.password ||
        !this.email ||
        !this.prenoms ||
        !this.nom ||
        !this.phone ||
        this.password != this.password_confirmation
      ) {
        Swal.fire({
          position: "center",
          icon: "info",
          title: "Remplissez tous les champs",
          showConfirmButton: true,
        });
      } else {
        if (!middlewarePhone.test(this.phone)) {
          Swal.fire({
            position: "center",
            icon: "info",
            title: "Le numéro de télephone doit comporter 10 chiffres",
            showConfirmButton: true,
          });
        } else {
          this.createAccount();
        }
      }
    },
    async createAccount() {
      this.loaderSpinner = true;
      try {
        let data = new FormData();
        data.append("nom", this.nom);
        data.append("prenoms", this.prenoms);
        data.append("email", this.email);
        data.append("phone", this.phone);
        data.append("lieu_habitation", this.lieu_habitation);
        data.append("card_bank", this.numberAccountBank);
        data.append("identity", this.proofPieces);
        data.append("password", this.password);

        const response = await axios.post("api/auth/signup", data);
        if (response.data.status == "true" || response.data.status === true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title:
              "Bravo vous venez de créer votre compte. Investissez librement!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.email = "";
          this.password = "";
          this.prenoms = "";
          this.nom = "";
          this.phone = "";
          this.password_confirmation = "";
          this.lieu_habitation = "";
          this.toogleTuto = true
          this.loaderSpinner = false;
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 1500,
        });
        this.toogleTuto = false
        this.loaderSpinner = false;
      }
    },

    handleSubmit() {
      this.load = true;
      axios
        .post("api/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((reponse) => {
          //console.log(reponse);
          if (reponse.data.access_token) {
            this.load = false;
            localStorage.setItem("user", JSON.stringify(reponse.data.user));
            localStorage.setItem("token", reponse.data.access_token);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "connexion réussie",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              window.location.reload(true);
              window.location.href = this.$route.query.redirect || "/";
            }, 1500);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              //   title: 'code',
              showConfirmButton: false,
              timer: 1500,
            });
            this.load = false;
          }
        })
        .catch((error) => {
          this.load = false;
          if (error) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Email ou mot de passe incorrect!",
              showConfirmButton: true,
            });
          }
        });
    },

    /* passwordReset(){
        //    //console.log("URL",this.url);
        //     //console.log("EMAIL",this.email);
           axios.post('https://igp-auth.lce-ci.com/api/auth/sendPasswordResetEmail',{
               email:this.email,
               url:location.protocol+"//"+location.host+"/",
           })
           .then(res => {
            //    //console.log("URL",this.url);

               //console.log("EMAILREPONSE",res.data);
                // Swal.fire({
                //        position: 'center',
                //        icon: 'success',
                //        title: 'verifier vos mail',
                //        showConfirmButton: false,
                //        timer: 1500
                // })
            if(res.data.state === true){
                 this.showMsg = false;
                 this.rei= false;
            }
           })
           .catch(error => {
               //console.log(error);
            if (error) {
                 Swal.fire({
                       position: 'center',
                       icon: 'error',
                       title: 'L\' adresse e-mail n\'existe pas',
                       showConfirmButton: false,
                       timer: 1500
                })
            }


           })
       }*/
  },
};
</script>
<style scoped>
h5{
  padding:.3em .2em;
}
.round_img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  object-fit: cover;
}
.conteneur-image {
  position: relative;
  width: 100%;
  height: 300px;
  box-shadow:1px 1px 10px rgba(0, 0, 0, 0.228) inset;
}
.conteneur-tuto {
  width: 90%;
  background: white;
  position:relative;
}
.close-tuto{
  position: absolute;
  z-index:999;
  right:0;
  top:.1em;
  background:red;
  border:none;
  color:white;
  padding:.5em;
}
@media screen and (min-width:600px) {
  .conteneur-tuto {
    width: 500px;
  }
}
.section-tuto {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.729);
  display: flex;

  justify-content: center;
  place-items: center;
  font-weight: bold;
}
.conteneur_password {
  position: relative;
}
.conteneur_password .seePassword {
  position: absolute;
  right: 0.5em;
  top: 20%;
  padding: 0 0.5em;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.76);
  border-radius: 5px;
  color: aliceblue;
  font-weight: bold;
}
.conteneur_piece {
  margin-top: 1em;
}
.conteneur_piece > label {
  text-decoration: underline;
}
.conteneur_piece input {
  margin-top: 5px;
  padding: 0.5em;
}
.label {
  color: rgb(148, 148, 148);
  font-size: 1.1em;
}
.form-control {
  border: 3px solid black !important;
  color: black !important;
  font-weight: bold !important;
  border-radius: 8px !important;
}
.form-file {
  background: transparent !important;
}
a {
  font-weight: 800;
}
.mt-1 {
  margin-top: 1em;
}
</style>
