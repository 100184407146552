<template>
  <div>
   
    <div class="confirmation_modifie_AcceptSuscrib" v-show="frameAcceptSuscrib">
      <div>
        
        <div class="conteneur-souscrire">
          <h4 class="text-center fw-bold p-5">Voulez-vous souscrire à ce package ? 
            <br> Cette action est irréversible !</h4>
          <div class="text-center">
            <button
            class="btn bg-info mx-2 text-white" 
            @click.prevent="SubscribePackage"
          >
            <span v-if="!turnSpinner">Oui</span>  <div class="spinner" v-if="turnSpinner"></div>
          </button>
          <button class="btn bg-danger mx-2 text-white" @click.prevent="notAccept">
            Retour
          </button>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation_modifie" v-show="showChoosePart">
      <div class="card">
        
      <div class="info-package-name">
       
           <strong class="badge bg-warning fw-bold">Nombre d'articles </strong>
        
        <h5 class="fw-bold text-light">{{nameProduit}}</h5>
        <span  @click.prevent="closeFrame">
          x
        </span>
      </div>
       
          <section class="part-btn-souscrire">
            <div v-if="typeFournisseur == 1" class="container-btn-susbcrire">
              <button class="btn-susbcrire gray">
               {{numberOfProduct}}
              </button>
            </div>
      <div class="container-btn-susbcrire" v-else>
              <button class="btn-susbcrire"
              :class="numberChoose == item ? 'gray':null"
               v-for="(item,index) in numberTimes" 
               :value="item" 
               :key="index"
               @click.prevent="chooseNumberArticle(item)">
               {{item}}
              </button>
            </div>
            <h4 class="text-center py-1" v-if="numberChoose">Nombre de pièces : <strong>{{numberChoose}}</strong></h4>
            <h4 class="text-center py-1" v-if="priceOfPackageWithNumber">Prix : <strong>{{moneyFormat.format(priceOfPackageWithNumber)}}Fcfa</strong></h4>
            <div class="text-center">
              <button
              :disabled="numberChoose || numberOfProduct ? false:true"
              class="btn bg-info mx-2 text-white" 
              @click.prevent="openWindowForSubscribe"
            >
              Oui
            </button>
            <button class="btn bg-danger mx-2 text-white" @click.prevent="closeFrame">
              Retour
            </button>
            </div>
          </section>
         
        </div>
    
    </div>
    <section class="p0 container-fluid banner about_banner">
      <div class="about_banner_opacity">
        <div class="container">
          <div class="banner_info_about">
            <h1>Nos Packages</h1>
            <ul>
              <li><router-link to="/">Accueil</router-link></li>
              <li><i class="fa fa-angle-right"></i></li>
              <li>Packages</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="shop_container faqs_sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-xs-12  pull-left left_side ">
            <h4>Catégories de package</h4>
            <div class="col-md-12">
              <ul>
                <li class="list_type"  :class="!colorArticle ? 'bg-warning':null" @click.prevent="allPackage()">
                  <span class="text-warning "
                 
                      >Tous</span
                    >
                </li>
              </ul>
              
              <ul
              
                class="p0 category_item"
                v-for="(item, index) in typePackages"
                :key="index"
              >
              
                <li class="list_type"
                 :class="colorArticle == item.id ? 'bg-warning':null" @click.prevent="chooseTypePackage(item.id)">
                  <span 
                  class="text-warning type_package"
                    >{{
                      item.libelle
                    }}</span
                  >
                </li>
              </ul>
           
            </div>
          </div>
          <div class="col-lg-9 col-xs-12 shop-page-content">
            <div class="spinnerPackage" v-show="spinnerPackage"></div>
            <div class="section-title-style-2">
              <h2>Packages</h2> 
            </div>
            <section>
              <h1 class="text-center" v-if="msgNotExist">Pas d'article trouvé
                .</h1>
            </section>
            <section class="section-package">
          
                <div class="conteneur_package"
                 v-for="item in paginatedData" :key="item.id" v-if="paginatedData.length" >
                  <div class="star_rate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg> {{item.rate_count}}/5
                  </div>
                  <div class="is_package_or_franchise">
                   <span v-if="item.franchise == 1">Franchise</span>
                   <span v-else>Package</span>
                  </div>
               
                  <div class="image">
                    <router-link :to="{ name: 'show', params: { id: item.id } }" >
                    <img v-if="item.photo"
                    :src="URL+item.photo"
                    :alt="item.photo"
                />
              </router-link>
                 
                  </div>
            
                  <h5>Package : <b class="desp">{{ item.libelle }} </b></h5>
                  <h5>Coût de vente :  <b class="desp"
                    >{{ moneyFormat.format(item.cout_vente) }} Fcfa</b
                  ></h5>
                  <h5>Coût unitaire : <b class="desp">{{ moneyFormat.format(item.cout_acquisition) }} Fcfa </b></h5>
                  <h5>Gain :  
                    <b class="desp">{{ moneyFormat.format(item.cout_vente - item.cout_acquisition)}} FCFA</b>
                  </h5>
                  <h5>Nombre de pièces : <b class="desp">{{ item.pieces_restantes}} </b></h5>
                  <div v-if="item.pieces_restantes > 0" class="text-center">
                    <Preloader v-if="load" color="red" scale="0.6" />
                    <button
                     v-if="item.franchise == 0"
                      class="souscrire btn btn-lg update"
                      @click="openFrame(item.id,item.cout_acquisition)"
                    >
                      Souscrire
                    </button>
                    <button class="souscrire btn btn-lg update" @click.prevent="openFrame(item.id)" v-if="item.franchise == 1">Soucrire</button>
                  </div>
                  <div v-else class="alert alert-danger" role="alert">
                    Package acheté
                </div>
                </div>

                <div class="spinner cont_spinner" v-else>
                  
                </div>
                <h1 v-if="!this.paginatedData.length && !msgNotExist" class="text-center">
                  Pas de packages
                </h1>

            </section>
            <ul class="d-flex justify-content-center conteneur_pagination " v-if="paginatedData.length">
              <li class="mx-3">
            <button  class="page next-prev" :class="isInFirstPage? 'null':'prev'" @click="onClickFirstPage" :disabled="isInFirstPage"  >
              &laquo;
            </button>
             </li>
              <li>
              <button class="pagination_btn page mx-3 "  v-for="(page,index) in pages" :key="index" @click="onClickPage(page.number)" 
              :class="{color:isPageActive(page.number)}"
              > {{page.number}}  
              </button>
              </li>
              <li class="mx-3">
              <button class="page next-prev" :class="isInLastPage ? 'null':'prev'"  @click="onClickNextPage" :disabled="isInLastPage" >
              &raquo;
              </button>
              </li>
              </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {URL} from "@/config";

import Preloader from '@/components/preloader.vue'
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "index",
  components: {
     Preloader,
  },
  data() {
    return {
      URL:URL,
      priceOfPackage:"",
      priceOfPackageWithNumber:"",
      numberOfProduct:"",
      typeFournisseur:"",
      packages: [],
      typePackages: [],
      numberTimes:[],
      user: JSON.parse(localStorage.getItem('user')),
      packageId: "",
      souscris: true,
      btn_souscrire: null,
      turnSpinner:false,
      isLoading: false,
      spinnerPackage:false,
      souscription: null,
      load: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showChoosePart:false,
      idPackage:"",
      nb_products:"",
      numberChoose:"",
      frameAcceptSuscrib:false,
      nameProduit:"",
      packagesForFilter:[],
      msgNotExist:false,
      colorArticle:"",
      perPage:9,
      currentPage:1,
      current:1,
      totalPages:"",
      maxVisibleButtons:3,
    };
  },
  computed: {
    startPage(){
            if(this.currentPage === 1) return 1;
            if(this.currentPage === this.totalPages)return this.totalPages - this.maxVisibleButtons + (this.maxVisibleButtons-1);
            return  this.currentPage - 1;
        },
        endPage(){
            return Math.min(this.startPage + this.maxVisibleButtons-1 , this.totalPages)
        },
        pages(){
            let range =[]
            for (let i = this.startPage; i <= this.endPage ; i++) {
                range.push({ number:i, isDisabled: i === this.currentPage
                });
            }
            return range;
        },
        isInFirstPage(){
            return this.currentPage === 1
        },
        isInLastPage(){
            return this.currentPage === this.totalPages
        },
    paginatedData(){
         let start = (this.currentPage * this.perPage) - this.perPage;
            let end = start + this.perPage;
         return this.packages.slice(start,end);
    },

  },
  methods: {
    onPageChange(page) {
    this.currentPage = page;
    },
    onClickFirstPage(){
           this.onPageChange(this.currentPage-1)
        },
         onClickPreviousPage(){
          
           this.onPageChange(this.currentPage-1)
        },
         onClickPage(page){
        this.onPageChange(page)
        },
         onClickNextPage(){
          
          this.onPageChange(this.currentPage + 1)
        },
         onClickLastPage(){
          this.onPageChange(this.totalPages) 
        },
         isPageActive(page){
           return this.currentPage === page
        },
    allPackage(){
      this.packages = this.packagesForFilter
      this.msgNotExist = false;
      this.totalPages = Math.ceil(this.packages.length / 9)
      this.colorArticle = ""
    },
    
    chooseTypePackage(idType){
      this.colorArticle = idType
      this.packages = []
     
     const packagesFilter = this.packagesForFilter.filter(pack =>  
     pack.type_id == idType
     )
     this.msgNotExist = !packagesFilter.length ? true : false;
     this.totalPages = Math.ceil(packagesFilter.length / 9)
     this.packages = packagesFilter
     
    //console.log("newtab",packagesFilter)
     
    },
    chooseNumberArticle(number){
    this.numberChoose = number
    this.priceOfPackageWithNumber = this.priceOfPackage * number
    // console.log("this.priceOfPackageWithNumber", this.priceOfPackageWithNumber)
    },
    closeFrame(){
      this.idPackage = ""
      this.priceOfPackage = ""
      this.priceOfPackageWithNumber = ""
      this.nb_products = ""
      this.numberTimes = []
      this.numberChoose = ""
      this.nameProduit = ""
    this.showChoosePart = !this.showChoosePart;  
    },
    openFrame(id,pricePackage=1){
      this.idPackage = id
      this.priceOfPackage = pricePackage
      this.packages.forEach(item=>{
        if(item.id == id){
          this.nameProduit = item.libelle
          this.numberOfProduct = item.franchise ? item.nb_products : null
          this.typeFournisseur = item.franchise
          //console.log("this.nameProduit",this.nameProduit)
          if(!item.franchise){
            for (let i = item.tranche; item.pieces_restantes >= i; i+=item.tranche){
            this.numberTimes.push(i)
           //console.log('NOMBRE FOIS',i)
          }
          }
          
          //console.log("ELEMENT",item)
        }
      })
      this.showChoosePart = !this.showChoosePart;  
    },
    getPackages() {
      this.spinnerPackage = true
      axios
        .get("api/publies")
        .then((res) => {
          //  = res.data.data;
          res.data.data.forEach(element => {
            if(element.pieces_restantes > 0){
              this.packages.push(element)
              this.packagesForFilter.push(element)
            }
          });
          this.totalPages = Math.ceil(this.packages.length / 9)
          //console.log("DATA", res.data.data);
          this.spinnerPackage = false
        })
        .catch((error) => {
          //console.log("Qdfe", error);
          this.spinnerPackage = false
        });
    },

    getTypePackages() {
      
      axios.get("api/types").then((res) => {
        //console.log("Types", res);
        this.typePackages = res.data.data;
      })
      .catch(err=>{
        //console.log(err)
      })
    },
    openWindowForSubscribe(){
      this.frameAcceptSuscrib = !this.frameAcceptSuscrib
    },
    notAccept(){
      this.frameAcceptSuscrib = !this.frameAcceptSuscrib
    },
    SubscribePackage() {
   
      if (!this.user) {
        this.$router.push('/auth')
      }else{
        this.turnSpinner = true
        let numberChoose = this.numberOfProduct ? this.numberOfProduct:this.numberChoose
        axios
            .post("api/achats", {
              user_id: this.user.id,
              package_id: this.idPackage,
              nb_pieces: numberChoose,
            })
            .then((res) => {
              //console.log("ACTIVE PACKAGES", res);
            
              
              if (res.data.status === true) {
                this.packages.forEach(item=>{
                if(item.id == res.data.data.id){
                  item.pieces_restantes = res.data.data.pieces_restantes
                }
              })
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Souscription effectuée avec succès",
                  showConfirmButton: true,
                
                });
                this.idPackage = ""
         this.nb_products = ""
        this.numberTimes = []
        this.numberChoose = ""
       this.showChoosePart = !this.showChoosePart; 
       this.frameAcceptSuscrib = !this.frameAcceptSuscrib
       this.turnSpinner = false
              }

              if(res.data.status === false){
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: res.data.message,
                  showConfirmButton: true,
                
                });
                this.turnSpinner = false
              }
               
             
            })
            .catch((error) => {
              //console.log(error);
              this.turnSpinner = false
            });
      
      }
      
      
    },

  
  },

  mounted() {
    this.getPackages();
    this.getTypePackages();
    // this.getachats();
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).data:null;
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    //console.log("USER", this.user.id);
  },
};
</script>

<style scoped>
.type_package{
  text-transform: capitalize;
}
.shop-page-content{
  position: relative;
}
.spinnerPackage {
  border: 5px solid #ccc; /* Taille et couleur des bordures */
  border-top: 5px solid #ff6a00; /* Couleur de la bordure supérieure */
  border-radius: 50%; /* Arrondir les coins pour créer un cercle */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Animation de rotation */
  position:absolute;
  margin-top: 10%;
  margin-left: 50%;
}

.star_rate .text-warning{
  color:rgb(255, 204, 0);
}
.star_rate{
  position:absolute;
  top:.5em;
  z-index: 30;
  right: .3em;
  padding:.1em;
  width:auto;
  font-weight:bold;
  height: auto;
  background: rgb(255, 255, 255);
  box-shadow:1px 1px 6px rgba(0, 0, 0, 0.282);
  border-radius:5px;
}
.is_package_or_franchise{
    position:absolute;
  top:.5em;
  z-index: 30;
  left:0;
  width: auto;
  height: auto;
  background: rgb(255, 162, 0);
  color:white;
  font-weight:bold;
  padding:.1em;
}
.is_package_or_franchise span{
  color:black;
}
li span{
  color:rgb(0, 0, 0);
  font-weight:bold;
}
.d-flex{
  display:flex;
  justify-content:center;
  align-items:center;
}

.conteneur-souscrire{
  background: white;
  padding:1em;
  width:300px;
  transform:translateX(-50%);
  margin-left:50%;
}
.part-btn-souscrire{
  padding:5.5em 0 1em 0;
}
.info-package-name span{
  position:absolute;
  right:.5em;
  top:.8em;
  color:red;
  font-size:1.2em;
  cursor: pointer;
  box-shadow: 1px 1px 6px rgb(255, 255, 255) inset;
  width:30px;
  height: 30px;
  border-radius:100%;
}
.card {
  width:90%;
  position:relative;
  height:350px;
  overflow-y:scroll;
  border-radius: 5px;
  background-color: white;
  font-weight: bold;
}
.info-package-name{
  position:fixed;
  width:inherit;
  padding:.5em 0 0 0;
  text-align:center;
  text-transform: capitalize;
  background:rgb(0, 0, 0);
  box-shadow: 1px 1px 6px rgba(241, 241, 241, 0.54);
}
.prev{
  background:rgb(227, 167, 17);
  color:white !important;
}
.pagination_btn{
  border:1px solid white;
}

.color{
  background:rgb(227, 167, 17);
  color:white !important;
  font-weight: bold !important;
}
.page{
  width:30px;
  height:30px;
  border-radius:10px;
}
.next-prev{
  border:none;
  color:rgb(227, 167, 17);
  font-weight: bold !important;
}
.list_type{
  margin:1.5em 0;
  cursor: pointer;
}
.section-package{
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
}
.conteneur_package{
  width:300px;
  position:relative;
  height:auto;
  padding:0 0 1em 0;
  text-align: left;
  margin:.5em;
  border-radius: 0 0 5px 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.148);
}

.conteneur_package .image{
  width:100%;
  height: 200px;
  background-color:rgb(255, 255, 255);
  position:relative;
}
.conteneur_package .image a img{
   width:100%;
   position:absolute;
   top:0;
   left:0;
   height: 100%;
   object-fit: cover;
}
.conteneur_package h5{
  padding:0 1em;
}
.update {
  position: relative;
  z-index: 5;
 
}

.update:hover.update::before {
  opacity: 1;
}

.update::before {
  transition: all 0.5s ease-in-out;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  /* border: 1px solid black; */
}

.btn-lg,.gray,.bg-warning{
  background-color:rgb(244, 179, 0) !important;
  color:black;
  font-weight:400;
  padding:.5em;
}
.desp {
  text-transform: uppercase;
  font-size: 15px !important;
  margin:0 .3em;
}
.mx-3{
  margin:0 .5em;
}
.meta span{
  text-align: left;
}
.meta button{
  margin:.5em 0;
  width: 200px;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  overflow-y:scroll;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
  font-weight: bold;
}
.confirmation_modifie_AcceptSuscrib{
  position: fixed;
  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;
  z-index: 91;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
  font-weight: bold;
}


.btn-susbcrire{
  width:auto;
  padding:0 1em;
  margin:.5em;
  height: 30px;
  background-color: grey;
  border:1px solid rgba(128, 128, 128, 0.187);
  border-radius: 5px;
  font-weight: bold;
}
.container-btn-susbcrire{
  padding:1em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
}
.bg-info{
  background-color:rgb(244, 179, 0);
  color:white;
  font-weight: bold;
}
.bg-danger{
  background-color:rgb(255, 0, 0);
  color:white;
}
.m-0{
  margin:0;
}
.mx-2{
  margin:0 1em;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #0d6efd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
