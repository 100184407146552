<template>
  <div>
    <section class="p0 container-fluid banner about_banner">
      <div class="about_banner_opacity">
        <div class="container">
          <div class="banner_info_about">
            <h1>A propos de nous</h1>
            <ul>
              <li><a href="/">Accueil</a></li>
              <li><i class="fa fa-angle-right"></i></li>
              <li>A propos de nous</li>
            </ul>
          </div>
          <!-- End Banner Info -->
        </div>
        <!-- End Container -->
      </div>
      <!-- End Banner_opacity -->
    </section>
    <section class="side_tab">
      <div class="container">
        <div class="row">
          <div class="white_bg col-lg-12 col-sm-12 col-xs-12">
            <div class="market_strategy">
              <h1 class="text-light">Qui sommes nous ?</h1>
              <h4 class="text-light ">
                Invest est une plateforme de mise en relation entre les
                investisseurs et les entreprises désirants faire un rabais sur
                un stock pour avoir de la trésorerie ou une bonne capacite de
                stockage puis le vendre et reverser une marge aux investisseurs.
                INVEST ne peux faire objet de poursuite judiciaire, et en cas de
                problème l’entreprise du souscripteur doit impérativement
                présenter le stock acheté par le client sous peine de poursuite
                judiciaire. Cependant Invest assure des critères d’éligibilité
                et de crédibilité des entreprises mise sur la plateforme.
              </h4>
            </div>
            <div class="container_btn">
              <button>
                <router-link to="/packages-index" class="nav-btn"
                  >Acheter des Packages</router-link
                >
              </button>
              <button>
                <router-link to="/contact" class="nav-btn"
                  >Contactez-nous</router-link
                >
              </button>
            </div>
          </div>
        </div>
        <!-- End row -->
      </div>
      <!-- End container -->
    </section>
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    this.infoUserWithoutData = JSON.parse(localStorage.getItem("user"));
    this.infoUserWithData = JSON.parse(localStorage.getItem("user")).data;
    this.user = this.infoUserWithData
      ? this.infoUserWithData
      : this.infoUserWithoutData;
    //console.log("USER", this.user.id);
  },
};
</script>
<style scoped>
.text-center {
  text-align: center;
}
h1 {
  font-weight: bold;
}
.text-light {
  color: white !important;
}
.market_strategy h1 {
  padding: 1em;
}
.market_strategy h4 {
  line-height:2em;
  font-weight: bold;
}
.container_btn button {
  width: auto !important;
  border: 2px solid rgb(255, 255, 255);
  height: auto !important;
  padding: 1em;
  margin: 1em;
  background: transparent;

  transition: all 0.3s ease-in-out;
}
.container_btn button a {
  color: white !important;
  padding: 1em;
}
.container_btn button:hover a {
  color: rgb(0, 0, 0) !important;
}
.container_btn button:hover {
  background: white;
}
.market_strategy h4 {
  text-align: left;

  letter-spacing: 0.1em;
}
.side_tab .white_bg:before {
  background: #0b0b0b !important;
  width: auto;
}
p {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}
</style>
