import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import VueRouter from 'vue-router'
import Default from "@/layouts/default.vue";
import Auth from "@/layouts/auth.vue";
import JwPagination from 'jw-vue-pagination';
import vGallery from 'v-gallery'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import rate from 'vue-rate'
import 'vue-rate/dist/vue-rate.css'

axios.defaults.baseURL = 'https://backend.invest-ci.com/';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

Vue.use(VueSweetalert2)

Vue.use(vGallery)
Vue.use(rate)
Vue.component('jw-pagination', JwPagination);

Vue.use(VueRouter)

Vue.use(ElementUI);
Vue.component('Default', Default);
Vue.component('Auth', Auth);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')