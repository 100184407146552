<template>
  <div class="form-box">
    <div class="tab tab-nav-underline tab-nav-boxed">
      <ul class="nav">
        <li class="nav-item mb-4">
          <h2 class="nav-link lh-1 ls-normal text-center">
            Modifier le profil
          </h2>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="signin">
          <form>
            <div class="form-group mb-5">
              <div class="divInpt">
                <input
                  v-model="user.nom"
                  class="mb-5"
                  type="text"
                  placeholder="nom"
                  required
                />
                <input
                  v-model="user.prenoms"
                  class="mb-5"
                  type="text"
                  placeholder="prénoms"
                  required
                />
                <input
                  v-model="user.phone"
                  class="mb-5"
                  type="tel"
                  placeholder="Télephone"
                  required
                />
                <input
                  v-model="user.email"
                  class="mb-5"
                  type="text"
                  placeholder="Entrez votre adresse e-mail"
                  required
                />
                <input
                  v-model="user.lieu_habitation"
                  class="mb-5"
                  type="text"
                  placeholder="Ville"
                  required
                />
                <input
                  v-model="password"
                  class="mb-5"
                  type="password"
                  name="singin-password"
                  placeholder="Mot de passe"
                  required
                />
                <input
                  v-model="password_confirmation"
                  class="mb-5"
                  type="password"
                  name="singin-password"
                  placeholder="Confirmation mot de passe"
                  required
                />
               
              </div>
            </div>
            <div class="text-center">
              <button
                class="update btn"
                type="submit"
                @click.prevent="modifierData"
              >
                Modifier votre compte
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import store from "../store";
export default {
  name: "profil",
  data() {
    return {
      password:null,
      user: JSON.parse(localStorage.getItem('user')),
      token: localStorage.getItem('token'),
      password_confirmation:""
    };
  },
  methods: {
    modifierData() {
   
    
      if(this.password_confirmation == this.password){
        this.user['password'] = this.password
        //console.log("this.user",this.user)
        const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
        axios
        .post("api/profile",this.user, config)
        .then((rep) => {
          //console.log(rep);
          if (rep.data.status) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Modification effectuée.",
              showConfirmButton: false,
              timer: 1500,
            });
            localStorage.setItem("user", JSON.stringify(rep.data.data));
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Modification echouée",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch(err => {
          //console.log(err)
        })
      }else{
               Swal.fire({
              position: "center",
              icon: "error",
              title: "Mot de passe incorrect.",
              showConfirmButton: true,
            });
      }
    
   
      
    },
  },
  mounted() {
       
    this.infoUserWithoutData = JSON.parse(localStorage.getItem('user'))
		this.infoUserWithData = JSON.parse(localStorage.getItem('user')).data
		this.user = this.infoUserWithData ? this.infoUserWithData : this.infoUserWithoutData
    //console.log("USER", this.user.id);
    // localStorage.getItem('token')
    // //console.log(localStorage.getItem('token'))
    //console.log(this.user);
    //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  },
};
</script>
<style scoped>
button{
  width: 200px !important;
  height:50px !important;
  background: gold !important;
  font-weight: bold;
}
.form-box {
  width: 50% !important;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 3em !important;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.384);
  margin-bottom: 5.5em !important;
  margin-top: 2em !important;
}
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"] {
  padding: 0.3em 0 !important;
  padding-left: 1em !important;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 10px !important;
}
@media screen and (max-width: 920px) {
  .form-box {
    width: 90% !important;
    padding: 1em !important;
  }
}
@media screen and (max-width: 330px) {
  .form-box {
    width: 90% !important;
    padding: 0.5em !important;
  }
  .btn {
    width: 200px !important;
    text-align: left;
    padding: 1em 0 !important;
  }
}
.divInpt {
  justify-content: center;
}

.update {
  position: relative;
  z-index: 5;
 
}

.update:hover.update::before {
  opacity: 1;
}

.update::before {
  transition: all 0.5s ease-in-out;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: goldenrod;
  opacity: 0;
  color:white;
}
</style>
