<template>
  <div id="app">
    <component :is="layout">
        <router-view />
    </component>
  </div>
</template>

<script>
const default_layout="default"
import axios from "axios";
export default {
  name: 'App',
 
  computed:{
    layout(){
      return (this.$route.meta.layout || default_layout);
    }
  },
  // mounted(){
  //   this.getUser()
  // },
  methods: {
    getUser() {
      axios.get("api/auth/user")
           .then((res) => {
        
          localStorage.setItem("user", JSON.stringify(res.data));
        })
        .catch(err=>{
          //console.log(err)
        })
    },
  },
}
</script>
<style>
@media screen and (max-width:790px) {
  footer{
    margin:20em 0 0 0;
  }
}
.vueperslide__title h1{
  color:white;
  font-weight: bold;
}
.vueperslides__arrow{
  background-color:black !important;
}
.vueperslide__content h3{
  color:white;
  font-weight: bold;
}

.multiselect__tags {
  min-height: 40px !important;
  display: block;
  padding: 8px 8px 0 8px;
  border-radius: 0px !important; 
  border: thin solid black !important;
  background: #fff;
  font-size: 14px;
}
.option__title{
  font-weight: bold;
  text-transform: capitalize;
}
.text-center{
  text-align: center;
}
.fw-bold{
  font-weight: bold;
}
.text-light{
  color:white !important;
}
.mx-2{
  margin:0 1em;
}
table td{
  border-left:1px solid black;
}
.first_letter{
   text-transform: capitalize;
}
</style>
